.cb-band.cb-band-hero .row:first-of-type {
  min-height: 576px;
  align-items: center;
}
@media (max-width: 1247.98px) {
  .cb-band.cb-band-hero .row:first-of-type {
    min-height: 552px;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero .row:first-of-type {
    min-height: auto;
  }
}
.cb-band.cb-band-hero .row.cb-band-hero-height-auto {
  min-height: auto !important;
}
.cb-band.cb-band-hero.cb-band-hero-bg,
.cb-band.cb-band-hero .cb-band-hero-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cb-band.cb-band-hero.cb-band-hero-xs-stack .cb-band-hero-content {
  padding: 0;
}
.cb-band.cb-band-hero .cb-band-hero-img-xs {
  display: none;
}
.cb-band.cb-band-hero .cb-band-hero-content {
  padding: 72px 0;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content {
    padding: 48px 0;
  }
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-text-overline {
  font-size: 1rem;
  line-height: 1.5em;
  display: inline-flex;
  padding-top: 8px;
  margin-bottom: 24px;
  border-width: 0;
  border-top-width: 1px;
  border-style: solid;
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-text-overline {
    margin-bottom: 16px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-text-overline {
    margin-bottom: 8px;
  }
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-band-hero-title {
  font-weight: 400;
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-band-hero-desc {
  font-size: 1.3125rem;
  line-height: 1.1428571429em;
  font-weight: 300;
}
@media (min-width: 768px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-band-hero-desc {
    font-size: 1.5rem;
    line-height: 1.3333333333em;
  }
}
@media (min-width: 1248px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-band-hero-desc {
    font-size: 2rem;
    line-height: 1.25em;
  }
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-band-hero-title + .cb-band-hero-desc {
  margin-top: 24px;
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-text-overline + .cb-band-hero-desc {
  margin-top: 0;
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-btn {
  font-size: 1rem;
  line-height: 1.5em;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-xs {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-xs-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (max-width: 767.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-xs-down {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-sm {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 768px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-sm-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (max-width: 1023.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-sm-down {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-md {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1024px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-md-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (max-width: 1247.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-md-down {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-lg {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1248px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-lg-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (max-width: 1343.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-lg-down {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-xl {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1344px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-xl-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (max-width: 1439.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-xl-down {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-2xl {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1440px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-2xl-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (max-width: 1727.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-2xl-down {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1728px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-3xl {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
@media (min-width: 1728px) {
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-3xl-up {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-btn.cb-btn-sm-3xl-down {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}
.cb-band.cb-band-hero .cb-band-hero-content .cb-btn-row {
  margin-top: 48px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero .cb-band-hero-content {
    text-align: left;
  }
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn-row {
    margin-top: 32px;
  }
  .cb-band.cb-band-hero .cb-band-hero-content .cb-btn {
    font-size: 0.875rem;
    line-height: 1.7142857143em;
  }
  .cb-band.cb-band-hero .cb-band-hero-img-xs {
    display: flex;
    flex-basis: auto;
    align-items: center;
    justify-content: center;
  }
  .cb-band.cb-band-hero.cb-band-hero-xs-stack .row:first-of-type {
    margin: 0 -24px;
  }
  .cb-band.cb-band-hero.cb-band-hero-xs-stack div[class^=col-]:not(.cb-band-hero-content) {
    padding-left: 0;
    padding-right: 0;
  }
  .cb-band.cb-band-hero.cb-band-hero-xs-stack .cb-band-hero-content {
    padding: 48px 24px;
  }
  .cb-band.cb-band-hero.cb-band-hero-xs-stack.cb-band-hero-bg,
.cb-band.cb-band-hero.cb-band-hero-xs-stack .cb-band-hero-bg {
    background-image: none !important;
  }
}
.cb-band.cb-band-panel {
  padding: 72px 0;
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-header .cb-band-panel-title {
  font-weight: 400;
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-header .cb-band-panel-title:not(h2) {
  letter-spacing: -0.5px;
  font-size: 1.5rem;
  line-height: 1.3333333333em;
}
@media (min-width: 768px) {
  .cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-header .cb-band-panel-title:not(h2) {
    font-size: 2rem;
    line-height: 1.25em;
  }
}
@media (min-width: 1248px) {
  .cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-header .cb-band-panel-title:not(h2) {
    font-size: 2.5rem;
    line-height: 1.2em;
  }
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-header .cb-text-overline {
  padding-top: 8px;
  display: inline-flex;
  font-size: 1rem;
  line-height: 1.5em;
  border-top: 1px solid #1e1e1e;
  margin-bottom: 24px;
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-desc {
  margin-top: 8px;
  font-size: 1rem;
  line-height: 1.5em;
}
@media (min-width: 1248px) {
  .cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-desc {
    font-size: 1.3125rem;
    line-height: 1.5238095238em;
  }
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-line {
  border-top: 1px solid #1e1e1e;
  margin-top: 16px;
  margin-bottom: 16px;
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-header + .cb-band-panel-components {
  margin-top: 48px;
}
.cb-band.cb-band-panel .cb-band-panel-content .cb-band-panel-footer {
  margin-top: 48px;
}
@media (min-width: 1024px) and (max-width: 1343.98px) {
  .cb-band.cb-band-panel .cb-band-panel-50-1 {
    padding-right: 24px;
  }
  .cb-band.cb-band-panel .cb-band-panel-50-2 {
    padding-left: 24px;
  }
}
@media (min-width: 1344px) {
  .cb-band.cb-band-panel .cb-band-panel-50-1 {
    padding-right: 36px;
  }
  .cb-band.cb-band-panel .cb-band-panel-50-2 {
    padding-left: 36px;
  }
}
.cb-band.cb-band-panel.cb-band-panel-image {
  background-size: cover;
  min-height: 580px;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
}
.cb-band.cb-band-panel.cb-band-panel-image.cb-band-panel-image-dark {
  position: relative;
}
.cb-band.cb-band-panel.cb-band-panel-image.cb-band-panel-image-dark::before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
}
.cb-band.cb-band-panel.cb-band-panel-radius-24 {
  position: relative;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  margin-top: -24px;
}

.cb-band.cb-band-hero.cb-band-hero-article .cb-band-hero-content .cb-band-hero-title + .cb-band-hero-desc {
  margin-top: 32px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero.cb-band-hero-article .cb-band-hero-content .cb-band-hero-title {
    font-size: 40px;
    line-height: 48px;
  }
  .cb-band.cb-band-hero.cb-band-hero-article .cb-band-hero-content .cb-band-hero-title + .cb-band-hero-desc {
    margin-top: 24px;
  }
}
.cb-band.cb-band-hero.cb-band-hero-article .cb-band-hero-content .cb-text-overline {
  margin-bottom: 40px;
  margin-bottom: 32px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero.cb-band-hero-article .cb-band-hero-content .cb-text-overline {
    margin-bottom: 24px;
  }
}
.cb-band.cb-band-hero.cb-band-hero-bg .cb-btn-row {
  margin-top: 24px;
}
.cb-band.cb-band-hero.cb-band-hero-focus .row:first-of-type {
  min-height: auto !important;
}
@media (max-width: 1343.98px) {
  .cb-band.cb-band-hero.cb-band-hero-focus .container {
    max-width: 100%;
  }
}
.cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-content {
  padding: 0 12px !important;
}
.cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-title {
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -1px;
}
.cb-band.cb-band-hero.cb-band-hero-focus .cb-btn-row {
  margin-top: 24px;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-content {
    padding: 24px 24px 48px 24px !important;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-content .cb-text-overline:first-child {
    margin-top: 24px;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-title {
    font-size: 40px !important;
    line-height: 48px !important;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-title + .cb-band-hero-desc {
    margin-top: 24px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-band.cb-band-hero.cb-band-hero-focus h1 {
    font-size: 36px !important;
    line-height: 42px !important;
    letter-spacing: -1px !important;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus h1 + .cb-band-hero-desc {
    margin-top: 16px;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-desc {
    font-size: 21px !important;
    line-height: 24px;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-title {
    font-size: 48px;
    line-height: 54px;
    letter-spacing: -1px;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-title + .cb-band-hero-desc {
    margin-top: 16px;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus .cb-band-hero-desc {
    font-size: 24px !important;
    line-height: 32px;
  }
  .cb-band.cb-band-hero.cb-band-hero-focus img {
    max-height: 480px;
  }
}
.cb-band.cb-band-hero.cb-band-hero-card {
  position: relative;
}
@media (min-width: 1248px) {
  .cb-band.cb-band-hero.cb-band-hero-card .cb-date-time-tags p {
    font-size: 1rem;
    line-height: 1.5em;
  }
}
@media (min-width: 1024px) {
  .cb-band.cb-band-hero.cb-band-hero-card .cb-band-hero-card-polygon {
    display: none;
  }
}
@media (max-width: 1023.98px) {
  .cb-band.cb-band-hero.cb-band-hero-card .cb-band-hero-card-polygon {
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    shape-outside: polygon(0px 300px, 100% 300px, 99.87% 0px, 0px 162px);
    clip-path: polygon(0px 300px, 100% 300px, 99.87% 0px, 0px 162px);
    height: 300px;
    width: 100%;
  }
  .cb-band.cb-band-hero.cb-band-hero-card .cb-card {
    margin-bottom: 72px;
  }
  .cb-band.cb-band-hero.cb-band-hero-card .cb-band-hero-content {
    padding-bottom: 64px;
  }
}
.cb-band.cb-band-hero.cb-band-hero-card .cb-card.cb-identity-card {
  width: auto;
  min-width: auto;
}
@media (min-width: 1024px) {
  .cb-band.cb-band-hero.cb-band-hero-card .cb-card.cb-identity-card {
    padding: 32px;
  }
}
@media (min-width: 1344px) {
  .cb-band.cb-band-hero.cb-band-hero-card .cb-card.cb-identity-card {
    padding: 48px;
  }
}
@media (max-width: 518px) {
  .cb-band.cb-band-hero.cb-band-hero-card .cb-card.cb-identity-card p {
    width: auto;
  }
}
.cb-band.cb-band-hero.cb-band-svg-pattern {
  position: relative;
}
.cb-band.cb-band-hero.cb-band-svg-pattern svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.cb-band.cb-band-hero.cb-band-svg-pattern.cb-palette-blue2 {
  background-color: #0077c8;
}
.cb-band.cb-band-hero.cb-band-svg-pattern.cb-palette-blue5 {
  background-color: #324dc7;
}
.cb-band.cb-band-hero.cb-band-svg-pattern.cb-palette-purple1 {
  background-color: #702f8a;
}
.cb-band.cb-band-hero.cb-band-svg-pattern.cb-palette-green1 {
  background-color: #3a913f;
}
.cb-band.cb-band-hero.cb-band-hero-illustration .row:first-of-type {
  min-height: 416px !important;
  align-items: unset;
}
.cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content {
  padding: 64px 0 !important;
}
@media (max-width: 375px) {
  .cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content {
    padding: 48px 0 !important;
  }
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content {
    text-align: center;
  }
}
.cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content .cb-band-hero-title {
  font-weight: 900;
  font-size: 3rem;
}
@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content .cb-band-hero-title {
    font-size: 2rem;
  }
}
.cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content .cb-band-hero-title + .cb-band-hero-desc {
  margin-top: 8px;
}
.cb-band.cb-band-hero.cb-band-hero-illustration .cb-band-hero-content .cb-band-hero-desc {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5em;
}
.cb-band.cb-band-hero.cb-band-hero-illustration .cb-btn-row {
  margin-top: 8px !important;
}
.cb-band.cb-band-hero.cb-band-hero-condensed .row:first-of-type {
  min-height: 288px;
}
@media (max-width: 1247.98px) {
  .cb-band.cb-band-hero.cb-band-hero-condensed .row:first-of-type {
    min-height: 288px;
  }
}
.cb-band.cb-band-hero.cb-band-hero-condensed .cb-band-hero-content {
  padding: 48px 0;
}
.cb-band.cb-band-hero.cb-band-hero-condensed .cb-band-hero-content .cb-text-overline {
  display: none;
}
.cb-band.cb-band-hero.cb-band-hero-condensed .cb-band-hero-content .cb-band-hero-desc {
  font-size: 1.5rem;
  line-height: 1.33em;
}
.cb-band.cb-band-hero.cb-palette-blue2 {
  background-color: #0077c8;
}
.cb-band.cb-band-hero.cb-palette-blue5 {
  background-color: #324dc7;
}
.cb-band.cb-band-hero.cb-palette-purple1 {
  background-color: #702f8a;
}
.cb-band.cb-band-hero.cb-palette-green1 {
  background-color: #3a913f;
}

.cb-svg-code {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

use.cb-svg-use {
  stroke: #1e1e1e;
}

.cb-palette-blue2 use.cb-svg-use {
  stroke: #1a84cd;
}

.cb-palette-blue5 use.cb-svg-use {
  stroke: #465fcc;
}

.cb-palette-purple1 use.cb-svg-use {
  stroke: #7e4495;
}

.cb-palette-green1 use.cb-svg-use {
  stroke: #4e9c52;
}

.cb-svg-pattern-code {
  width: 0;
  height: 0;
}

.cb-svg-pattern path,
.cb-svg-pattern rect,
.cb-svg-pattern line {
  stroke: inherit;
}

@keyframes grow {
  from {
    bottom: 0;
    margin-left: -100px;
    height: 50px;
    width: 200px;
  }
  to {
    bottom: 20px;
    margin-left: 0;
    height: 10px;
    width: 15px;
  }
}
@keyframes bounce {
  from {
    bottom: 20px;
  }
  to {
    bottom: 100%;
  }
}