.cb-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  color: #324dc7;
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #324dc7;
  box-sizing: border-box;
  border-radius: 999px;
  padding: 11px 23px 11px 23px;
  transition: all 0.25s ease-in-out;
}
.cb-btn .cb-glyph,
.cb-btn .cb-icon {
  font-size: 1em !important;
  line-height: 1.7142857143em !important;
}
.cb-btn:hover, .cb-btn.cb-hover {
  background-color: #f0f0f0;
  box-shadow: inset 0 0 0 1px #324dc7;
  text-decoration: none;
}
.cb-btn.cb-hover-shade:hover, .cb-btn.cb-hover-shade.cb-hover {
  background: rgba(40, 54, 154, 0.16);
}
.cb-btn:focus, .cb-btn.cb-focus {
  outline: none;
  background-color: transparent;
  text-decoration: underline;
  box-shadow: inset 0 0 0 2px #324dc7;
}
.cb-btn.cb-disabled, .cb-btn:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn.cb-disabled span, .cb-btn:disabled span {
  text-decoration: none !important;
}
.cb-btn:not(.cb-btn-square):not(.cb-btn-circle) .cb-glyph,
.cb-btn:not(.cb-btn-square):not(.cb-btn-circle) .cb-icon {
  margin-left: 8px;
}
.cb-btn:not(.cb-btn-square):not(.cb-btn-circle).cb-btn-icon-left .cb-glyph,
.cb-btn:not(.cb-btn-square):not(.cb-btn-circle).cb-btn-icon-left .cb-icon {
  margin-left: 0;
  margin-right: 8px;
}

.cb-btn-sm:not(.cb-btn-tag),
.cb-btn-small:not(.cb-btn-tag) {
  padding: 7px 23px;
}

.cb-btn-lg:not(.cb-btn-card-yellow), .cb-btn-lg:not(.cb-btn-card-primary), .cb-btn-lg:not(.cb-btn-card-secondary), .cb-btn-lg:not(.cb-btn-square), .cb-btn-lg:not(.cb-btn-circle), .cb-btn-lg:not(.cb-btn-floating), .cb-btn-lg:not(.cb-btn-greyscale), .cb-btn-lg:not(.cb-btn-naked) {
  padding: 19px 47px;
  font-size: 1.1875rem;
  line-height: 1.2631578947em;
  font-weight: 500;
}

[class*=safari] .cb-btn:not(.cb-btn-circle):not(.cb-btn-tag):not(.cb-btn-square) {
  padding-top: 13px;
  padding-bottom: 10px;
}
[class*=safari] .cb-btn:not(.cb-btn-circle):not(.cb-btn-tag):not(.cb-btn-square).cb-btn-sm, [class*=safari] .cb-btn:not(.cb-btn-circle):not(.cb-btn-tag):not(.cb-btn-square).cb-btn-small {
  padding-top: 9px;
  padding-bottom: 6px;
}
[class*=safari] .cb-btn.cb-btn-tag {
  padding-bottom: 5px;
}
[class*=safari] .cb-btn.cb-btn-tag span:first-of-type {
  padding-top: 2px;
}
[class*=safari] .cb-btn.cb-btn-tag.cb-btn-sm, [class*=safari] .cb-btn.cb-btn-tag.cb-btn-small {
  padding: 3px 8px 1px 8px;
}

.cb-btn-light {
  color: #ffffff;
  border-color: #ffffff;
}
.cb-btn-light:hover, .cb-btn-light.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
}
.cb-btn-light:focus, .cb-btn-light.cb-focus {
  border-color: #ffffff;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffffff;
  text-decoration: underline;
}
.cb-btn-light.cb-disabled, .cb-btn-light:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-light:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}

.cb-btn-black {
  color: #1e1e1e;
  border-color: #1e1e1e;
  color: #1e1e1e;
}
.cb-btn-black:hover, .cb-btn-black.cb-hover, .cb-btn-black:focus, .cb-btn-black.cb-focus {
  color: #1e1e1e;
}
.cb-btn-black:active, .cb-btn-black.cb-active {
  color: #505050;
}
.cb-btn-black:visited, .cb-btn-black.cb-visited {
  color: #1e1e1e;
}
.cb-btn-black.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-btn-black.cb-disabled:hover, .cb-btn-black.cb-disabled.cb-hover, .cb-btn-black.cb-disabled:focus, .cb-btn-black.cb-disabled.cb-focus, .cb-btn-black.cb-disabled.cb-active, .cb-btn-black.cb-disabled:visited, .cb-btn-black.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-btn-black:hover, .cb-btn-black.cb-hover {
  box-shadow: inset 0 0 0 1px #1e1e1e;
}
.cb-btn-black.cb-hover-shade:hover, .cb-btn-black.cb-hover-shade.cb-hover {
  background: rgba(0, 0, 0, 0.16);
}
.cb-btn-black.cb-white-bg:hover, .cb-btn-black.cb-white-bg.cb-hover {
  background: #f0f0f0 !important;
}
.cb-btn-black:focus, .cb-btn-black.cb-focus {
  box-shadow: inset 0 0 0 2px #1e1e1e;
}
.cb-btn-black.cb-disabled, .cb-btn-black:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-black.cb-disabled span, .cb-btn-black:disabled span {
  text-decoration: none !important;
}
.cb-btn-black.cb-btn-light {
  color: #ffffff;
  border-color: #ffffff;
}
.cb-btn-black.cb-btn-light:hover, .cb-btn-black.cb-btn-light.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
}
.cb-btn-black.cb-btn-light:focus, .cb-btn-black.cb-btn-light.cb-focus {
  border-color: #ffffff;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffffff;
  text-decoration: underline;
}
.cb-btn-black.cb-btn-light.cb-disabled, .cb-btn-black.cb-btn-light:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-black.cb-btn-light:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-black.cb-btn-light:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-black.cb-btn-light:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-black.cb-btn-light:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-black.cb-palette-blue2 {
  color: #0077c8;
  border-color: #0077c8;
}
.cb-btn-black.cb-palette-blue2:hover, .cb-btn-black.cb-palette-blue2.cb-hover {
  box-shadow: inset 0 0 0 1px #0077c8;
  background-color: #e6f1fa;
}
.cb-btn-black.cb-palette-blue2:focus, .cb-btn-black.cb-palette-blue2.cb-focus {
  box-shadow: inset 0 0 0 2px #0077c8;
}
.cb-btn-black.cb-palette-blue5 {
  color: #324dc7;
  border-color: #324dc7;
}
.cb-btn-black.cb-palette-blue5:hover, .cb-btn-black.cb-palette-blue5.cb-hover {
  box-shadow: inset 0 0 0 1px #324dc7;
  background-color: #ebedf9;
}
.cb-btn-black.cb-palette-blue5:focus, .cb-btn-black.cb-palette-blue5.cb-focus {
  box-shadow: inset 0 0 0 2px #324dc7;
}
.cb-btn-black.cb-palette-purple1 {
  color: #702f8a;
  border-color: #702f8a;
}
.cb-btn-black.cb-palette-purple1:hover, .cb-btn-black.cb-palette-purple1.cb-hover {
  box-shadow: inset 0 0 0 1px #702f8a;
  background-color: #f1eaf3;
}
.cb-btn-black.cb-palette-purple1:focus, .cb-btn-black.cb-palette-purple1.cb-focus {
  box-shadow: inset 0 0 0 2px #702f8a;
}
.cb-btn-black.cb-palette-green1 {
  color: #3a913f;
  border-color: #3a913f;
}
.cb-btn-black.cb-palette-green1:hover, .cb-btn-black.cb-palette-green1.cb-hover {
  box-shadow: inset 0 0 0 1px #3a913f;
  background-color: #ebf4ec;
}
.cb-btn-black.cb-palette-green1:focus, .cb-btn-black.cb-palette-green1.cb-focus {
  box-shadow: inset 0 0 0 2px #3a913f;
}

.cb-btn-primary {
  background-color: #324dc7;
  color: #ffffff;
  border-color: #324dc7;
}
.cb-btn-primary:hover, .cb-btn-primary.cb-hover {
  background-color: #192764;
  border-color: #192764;
  box-shadow: none;
}
.cb-btn-primary:focus, .cb-btn-primary.cb-focus {
  border-color: #ffffff;
  background-color: #324dc7;
  box-shadow: 0 0 0 2px #324dc7;
}
.cb-btn-primary:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-primary:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-primary.cb-disabled, .cb-btn-primary:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-primary.cb-disabled span, .cb-btn-primary:disabled span {
  text-decoration: none !important;
}
.cb-btn-primary.cb-palette-blue2 {
  background-color: #0077c8;
  border-color: #0077c8;
}
.cb-btn-primary.cb-palette-blue2:hover, .cb-btn-primary.cb-palette-blue2.cb-hover {
  background-color: #003c64;
  border-color: #003c64;
}
.cb-btn-primary.cb-palette-blue2:focus, .cb-btn-primary.cb-palette-blue2.cb-focus {
  border-color: #ffffff;
  background-color: #0077c8;
  box-shadow: 0 0 0 2px #0077c8;
}
.cb-btn-primary.cb-palette-blue5 {
  background-color: #324dc7;
  border-color: #324dc7;
}
.cb-btn-primary.cb-palette-blue5:hover, .cb-btn-primary.cb-palette-blue5.cb-hover {
  background-color: #192764;
  border-color: #192764;
}
.cb-btn-primary.cb-palette-blue5:focus, .cb-btn-primary.cb-palette-blue5.cb-focus {
  border-color: #ffffff;
  background-color: #324dc7;
  box-shadow: 0 0 0 2px #324dc7;
}
.cb-btn-primary.cb-palette-purple1 {
  background-color: #702f8a;
  border-color: #702f8a;
}
.cb-btn-primary.cb-palette-purple1:hover, .cb-btn-primary.cb-palette-purple1.cb-hover {
  background-color: #381845;
  border-color: #381845;
}
.cb-btn-primary.cb-palette-purple1:focus, .cb-btn-primary.cb-palette-purple1.cb-focus {
  border-color: #ffffff;
  background-color: #702f8a;
  box-shadow: 0 0 0 2px #702f8a;
}
.cb-btn-primary.cb-palette-green1 {
  background-color: #3a913f;
  border-color: #3a913f;
}
.cb-btn-primary.cb-palette-green1:hover, .cb-btn-primary.cb-palette-green1.cb-hover {
  background-color: #1d4920;
  border-color: #1d4920;
}
.cb-btn-primary.cb-palette-green1:focus, .cb-btn-primary.cb-palette-green1.cb-focus {
  border-color: #ffffff;
  background-color: #3a913f;
  box-shadow: 0 0 0 2px #3a913f;
}
.cb-btn-primary.cb-btn-light {
  background-color: #ffffff;
  color: #324dc7;
  border-color: #ffffff;
}
.cb-btn-primary.cb-btn-light:hover, .cb-btn-primary.cb-btn-light.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff;
}
.cb-btn-primary.cb-btn-light:focus, .cb-btn-primary.cb-btn-light.cb-focus {
  color: #324dc7;
  border-color: #324dc7;
  box-shadow: 0 0 0 3px #ffffff;
}
.cb-btn-primary.cb-btn-light.cb-disabled, .cb-btn-primary.cb-btn-light:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-primary.cb-btn-light:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-primary.cb-btn-light:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-primary.cb-btn-light:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-primary.cb-btn-light:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
  color: #324dc7;
}
.cb-btn-primary.cb-btn-light.cb-palette-blue2 {
  color: #0077c8;
}
.cb-btn-primary.cb-btn-light.cb-palette-blue5 {
  color: #324dc7;
}
.cb-btn-primary.cb-btn-light.cb-palette-purple1 {
  color: #702f8a;
}
.cb-btn-primary.cb-btn-light.cb-palette-green1 {
  color: #3a913f;
}

.cb-btn-yellow {
  color: #1e1e1e;
  border-color: #1e1e1e;
  background-color: #fedb00;
}
.cb-btn-yellow:hover, .cb-btn-yellow.cb-hover {
  background-color: #fedb00;
  box-shadow: inset 0 0 0 1px #1e1e1e;
  text-decoration: none;
}
.cb-btn-yellow:focus, .cb-btn-yellow.cb-focus {
  background-color: #fedb00;
  border-color: #ffffff;
  box-shadow: 0 0 0 2px #1e1e1e;
}
.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-yellow:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #1e1e1e;
}
.cb-btn-yellow.cb-disabled, .cb-btn-yellow:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-yellow.cb-disabled span, .cb-btn-yellow:disabled span {
  text-decoration: none !important;
}
.cb-btn-yellow.cb-btn-light {
  background-color: #ffffff;
  color: #1e1e1e;
  border-color: #ffffff;
}
.cb-btn-yellow.cb-btn-light:hover, .cb-btn-yellow.cb-btn-light.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff !important;
}
.cb-btn-yellow.cb-btn-light:focus, .cb-btn-yellow.cb-btn-light.cb-focus {
  color: #1e1e1e;
  border-color: #1e1e1e;
  box-shadow: 0 0 0 3px #ffffff;
}
.cb-btn-yellow.cb-btn-light.cb-disabled, .cb-btn-yellow.cb-btn-light:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4) !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-yellow.cb-btn-light:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-yellow.cb-btn-light:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-yellow.cb-btn-light:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-yellow.cb-btn-light:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
  color: #1e1e1e;
}

.cb-btn-card-primary {
  color: #1e1e1e;
  border-color: #1e1e1e;
  font-weight: 400;
  color: #1e1e1e;
}
.cb-btn-card-primary:hover, .cb-btn-card-primary.cb-hover, .cb-btn-card-primary:focus, .cb-btn-card-primary.cb-focus {
  color: #1e1e1e;
}
.cb-btn-card-primary:active, .cb-btn-card-primary.cb-active {
  color: #505050;
}
.cb-btn-card-primary:visited, .cb-btn-card-primary.cb-visited {
  color: #1e1e1e;
}
.cb-btn-card-primary.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-btn-card-primary.cb-disabled:hover, .cb-btn-card-primary.cb-disabled.cb-hover, .cb-btn-card-primary.cb-disabled:focus, .cb-btn-card-primary.cb-disabled.cb-focus, .cb-btn-card-primary.cb-disabled.cb-active, .cb-btn-card-primary.cb-disabled:visited, .cb-btn-card-primary.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-btn-card-primary.cb-btn-sm, .cb-btn-card-primary.cb-btn-small {
  padding: 7px 15px;
  font-size: 0.875rem;
  line-height: 1.1428571429em;
}
.cb-btn-card-primary:hover, .cb-btn-card-primary.cb-hover {
  box-shadow: inset 0 0 0 1px #1e1e1e;
}
.cb-btn-card-primary:focus, .cb-btn-card-primary.cb-focus {
  box-shadow: inset 0 0 0 2px #1e1e1e;
}
.cb-btn-card-primary.cb-disabled, .cb-btn-card-primary:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-card-primary.cb-disabled span, .cb-btn-card-primary:disabled span {
  text-decoration: none !important;
}

.cb-btn-card-secondary {
  background-color: transparent;
  border-color: transparent;
  color: #1e1e1e;
  font-weight: 500;
  text-decoration: underline;
  border-radius: 0;
  padding: 11px 0;
}
.cb-btn-card-secondary:hover, .cb-btn-card-secondary.cb-hover {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  box-shadow: none;
  color: #324dc7;
}
.cb-btn-card-secondary.cb-palette-blue2:hover, .cb-btn-card-secondary.cb-palette-blue2.cb-hover {
  color: #0077c8;
}
.cb-btn-card-secondary.cb-palette-blue5:hover, .cb-btn-card-secondary.cb-palette-blue5.cb-hover {
  color: #324dc7;
}
.cb-btn-card-secondary.cb-palette-purple1:hover, .cb-btn-card-secondary.cb-palette-purple1.cb-hover {
  color: #702f8a;
}
.cb-btn-card-secondary.cb-palette-green1:hover, .cb-btn-card-secondary.cb-palette-green1.cb-hover {
  color: #3a913f;
}
.cb-btn-card-secondary:focus, .cb-btn-card-secondary.cb-focus {
  position: relative;
  border-color: transparent !important;
  background-color: transparent;
  box-shadow: none !important;
  outline: none !important;
}
.cb-btn-card-secondary:focus::after, .cb-btn-card-secondary.cb-focus::after {
  position: absolute;
  content: "";
  display: block;
  width: calc(100% + 42px);
  height: calc(100% + 2px);
  top: -1px;
  left: -20px;
  border-radius: 999px;
  box-shadow: inset 0 0 0 2px #1e1e1e;
}
.cb-btn-card-secondary.cb-btn-sm, .cb-btn-card-secondary.cb-btn-small {
  padding: 7px 0;
  font-size: 0.875rem;
  line-height: 1.1428571429em;
}
.cb-btn-card-secondary.cb-btn-sm:focus::after, .cb-btn-card-secondary.cb-btn-sm.cb-focus::after, .cb-btn-card-secondary.cb-btn-small:focus::after, .cb-btn-card-secondary.cb-btn-small.cb-focus::after {
  width: calc(100% + 24px);
  height: calc(100% + 2px);
  top: -1px;
  left: -12px;
}
.cb-btn-card-secondary.cb-disabled, .cb-btn-card-secondary:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-card-secondary.cb-disabled span, .cb-btn-card-secondary:disabled span {
  text-decoration: none !important;
}

.cb-btn-filter {
  color: #1e1e1e;
  border-color: #d9d9d9;
  color: #1e1e1e;
}
.cb-btn-filter:hover, .cb-btn-filter.cb-hover, .cb-btn-filter:focus, .cb-btn-filter.cb-focus {
  color: #1e1e1e;
}
.cb-btn-filter:active, .cb-btn-filter.cb-active {
  color: #505050;
}
.cb-btn-filter:visited, .cb-btn-filter.cb-visited {
  color: #1e1e1e;
}
.cb-btn-filter.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-btn-filter.cb-disabled:hover, .cb-btn-filter.cb-disabled.cb-hover, .cb-btn-filter.cb-disabled:focus, .cb-btn-filter.cb-disabled.cb-focus, .cb-btn-filter.cb-disabled.cb-active, .cb-btn-filter.cb-disabled:visited, .cb-btn-filter.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-btn-filter:hover, .cb-btn-filter.cb-hover {
  box-shadow: inset 0 0 0 1px #1e1e1e;
  background-color: #f0f0f0;
}
.cb-btn-filter:focus, .cb-btn-filter.cb-focus {
  box-shadow: inset 0 0 0 2px #1e1e1e;
}
.cb-btn-filter.cb-btn-light {
  background-color: #ffffff;
}
.cb-btn-filter.cb-btn-light:hover, .cb-btn-filter.cb-btn-light:focus {
  color: #1e1e1e !important;
  background-color: #f0f0f0;
}
.cb-btn-filter.cb-disabled, .cb-btn-filter:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-filter.cb-disabled span, .cb-btn-filter:disabled span {
  text-decoration: none !important;
}
.cb-btn-filter.cb-filter-active, .cb-btn-filter.cb-filter-open {
  color: #324dc7;
  border-color: #324dc7;
  background-color: #f5f7fc;
}
.cb-btn-filter.cb-filter-active:hover, .cb-btn-filter.cb-filter-active.cb-hover, .cb-btn-filter.cb-filter-open:hover, .cb-btn-filter.cb-filter-open.cb-hover {
  box-shadow: inset 0 0 0 1px #324dc7;
  background-color: #e6edf8;
}
.cb-btn-filter.cb-filter-active:focus, .cb-btn-filter.cb-filter-active.cb-focus, .cb-btn-filter.cb-filter-open:focus, .cb-btn-filter.cb-filter-open.cb-focus {
  box-shadow: inset 0 0 0 2px #324dc7;
  background-color: #ffffff;
}
.cb-btn-filter.cb-palette-blue2.cb-filter-active, .cb-btn-filter.cb-palette-blue2.cb-filter-open {
  color: #0077c8 !important;
  border-color: #0077c8;
  background-color: #f2f8fc;
}
.cb-btn-filter.cb-palette-blue2.cb-filter-active:hover, .cb-btn-filter.cb-palette-blue2.cb-filter-active.cb-hover, .cb-btn-filter.cb-palette-blue2.cb-filter-open:hover, .cb-btn-filter.cb-palette-blue2.cb-filter-open.cb-hover {
  box-shadow: inset 0 0 0 1px #0077c8;
  background-color: #e6f1fa;
}
.cb-btn-filter.cb-palette-blue2.cb-filter-active:focus, .cb-btn-filter.cb-palette-blue2.cb-filter-active.cb-focus, .cb-btn-filter.cb-palette-blue2.cb-filter-open:focus, .cb-btn-filter.cb-palette-blue2.cb-filter-open.cb-focus {
  box-shadow: inset 0 0 0 2px #0077c8;
  background-color: #ffffff;
}
.cb-btn-filter.cb-palette-blue5.cb-filter-active, .cb-btn-filter.cb-palette-blue5.cb-filter-open {
  color: #324dc7 !important;
  border-color: #324dc7;
  background-color: #f5f6fc;
}
.cb-btn-filter.cb-palette-blue5.cb-filter-active:hover, .cb-btn-filter.cb-palette-blue5.cb-filter-active.cb-hover, .cb-btn-filter.cb-palette-blue5.cb-filter-open:hover, .cb-btn-filter.cb-palette-blue5.cb-filter-open.cb-hover {
  box-shadow: inset 0 0 0 1px #324dc7;
  background-color: #ebedf9;
}
.cb-btn-filter.cb-palette-blue5.cb-filter-active:focus, .cb-btn-filter.cb-palette-blue5.cb-filter-active.cb-focus, .cb-btn-filter.cb-palette-blue5.cb-filter-open:focus, .cb-btn-filter.cb-palette-blue5.cb-filter-open.cb-focus {
  box-shadow: inset 0 0 0 2px #324dc7;
  background-color: #ffffff;
}
.cb-btn-filter.cb-palette-purple1.cb-filter-active, .cb-btn-filter.cb-palette-purple1.cb-filter-open {
  color: #702f8a !important;
  border-color: #702f8a;
  background-color: #f8f5f9;
}
.cb-btn-filter.cb-palette-purple1.cb-filter-active:hover, .cb-btn-filter.cb-palette-purple1.cb-filter-active.cb-hover, .cb-btn-filter.cb-palette-purple1.cb-filter-open:hover, .cb-btn-filter.cb-palette-purple1.cb-filter-open.cb-hover {
  box-shadow: inset 0 0 0 1px #702f8a;
  background-color: #f1eaf3;
}
.cb-btn-filter.cb-palette-purple1.cb-filter-active:focus, .cb-btn-filter.cb-palette-purple1.cb-filter-active.cb-focus, .cb-btn-filter.cb-palette-purple1.cb-filter-open:focus, .cb-btn-filter.cb-palette-purple1.cb-filter-open.cb-focus {
  box-shadow: inset 0 0 0 2px #702f8a;
  background-color: #ffffff;
}
.cb-btn-filter.cb-palette-green1.cb-filter-active, .cb-btn-filter.cb-palette-green1.cb-filter-open {
  color: #3a913f !important;
  border-color: #3a913f;
  background-color: #f5faf5;
}
.cb-btn-filter.cb-palette-green1.cb-filter-active:hover, .cb-btn-filter.cb-palette-green1.cb-filter-active.cb-hover, .cb-btn-filter.cb-palette-green1.cb-filter-open:hover, .cb-btn-filter.cb-palette-green1.cb-filter-open.cb-hover {
  box-shadow: inset 0 0 0 1px #3a913f;
  background-color: #ebf4ec;
}
.cb-btn-filter.cb-palette-green1.cb-filter-active:focus, .cb-btn-filter.cb-palette-green1.cb-filter-active.cb-focus, .cb-btn-filter.cb-palette-green1.cb-filter-open:focus, .cb-btn-filter.cb-palette-green1.cb-filter-open.cb-focus {
  box-shadow: inset 0 0 0 2px #3a913f;
  background-color: #ffffff;
}

.cb-btn-date-picker .cb-glyph,
.cb-btn-date-picker .cb-icon {
  margin-right: 12px;
  margin-left: 0 !important;
}
.cb-btn-date-picker .cb-date-picker-end {
  position: relative;
  padding-left: 25px;
}
.cb-btn-date-picker .cb-date-picker-end::before {
  display: block;
  position: absolute;
  top: 4px;
  left: 12px;
  content: "";
  width: 1px;
  height: 16px;
  background-color: #1e1e1e;
}
.cb-btn-date-picker.cb-filter-open .cb-date-picker-end::before, .cb-btn-date-picker.cb-filter-active .cb-date-picker-end::before {
  background-color: #324dc7;
}
.cb-btn-date-picker.cb-palette-blue2.cb-filter-active .cb-date-picker-end::before, .cb-btn-date-picker.cb-palette-blue2.cb-filter-open .cb-date-picker-end::before {
  background-color: #0077c8;
}
.cb-btn-date-picker.cb-palette-blue5.cb-filter-active .cb-date-picker-end::before, .cb-btn-date-picker.cb-palette-blue5.cb-filter-open .cb-date-picker-end::before {
  background-color: #324dc7;
}
.cb-btn-date-picker.cb-palette-purple1.cb-filter-active .cb-date-picker-end::before, .cb-btn-date-picker.cb-palette-purple1.cb-filter-open .cb-date-picker-end::before {
  background-color: #702f8a;
}
.cb-btn-date-picker.cb-palette-green1.cb-filter-active .cb-date-picker-end::before, .cb-btn-date-picker.cb-palette-green1.cb-filter-open .cb-date-picker-end::before {
  background-color: #3a913f;
}

@media (min-width: 1px) and (max-width: 767.98px) {
  .cb-btn-light-xs {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xs:hover, .cb-btn-light-xs.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xs:focus, .cb-btn-light-xs.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xs.cb-disabled, .cb-btn-light-xs:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xs:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-primary:hover, .cb-btn-light-xs.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-primary:focus, .cb-btn-light-xs.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-disabled, .cb-btn-light-xs.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xs.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xs.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-black:hover, .cb-btn-light-xs.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xs.cb-btn-black:focus, .cb-btn-light-xs.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xs.cb-btn-black.cb-disabled, .cb-btn-light-xs.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-yellow:hover, .cb-btn-light-xs.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-xs.cb-btn-yellow:focus, .cb-btn-light-xs.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xs.cb-btn-yellow.cb-disabled, .cb-btn-light-xs.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xs.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-xs,
.cb-btn-small-xs {
    padding: 7px 23px;
  }
}
@media (min-width: 1px) {
  .cb-btn-light-xs-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-up:hover, .cb-btn-light-xs-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xs-up:focus, .cb-btn-light-xs-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xs-up.cb-disabled, .cb-btn-light-xs-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-primary:hover, .cb-btn-light-xs-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-primary:focus, .cb-btn-light-xs-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-primary.cb-disabled, .cb-btn-light-xs-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-xs-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xs-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xs-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xs-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xs-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-black:hover, .cb-btn-light-xs-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-black:focus, .cb-btn-light-xs-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xs-up.cb-btn-black.cb-disabled, .cb-btn-light-xs-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-yellow:hover, .cb-btn-light-xs-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-xs-up.cb-btn-yellow:focus, .cb-btn-light-xs-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-yellow.cb-disabled, .cb-btn-light-xs-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-xs-up,
.cb-btn-small-xs-up {
    padding: 7px 23px;
  }
}
@media (max-width: 767.98px) {
  .cb-btn-light-xs-down {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-down:hover, .cb-btn-light-xs-down.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xs-down:focus, .cb-btn-light-xs-down.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xs-down.cb-disabled, .cb-btn-light-xs-down:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-primary:hover, .cb-btn-light-xs-down.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-primary:focus, .cb-btn-light-xs-down.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-primary.cb-disabled, .cb-btn-light-xs-down.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-xs-down.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xs-down.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xs-down.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xs-down.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xs-down.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-black:hover, .cb-btn-light-xs-down.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-black:focus, .cb-btn-light-xs-down.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xs-down.cb-btn-black.cb-disabled, .cb-btn-light-xs-down.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-yellow:hover, .cb-btn-light-xs-down.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-xs-down.cb-btn-yellow:focus, .cb-btn-light-xs-down.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-yellow.cb-disabled, .cb-btn-light-xs-down.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xs-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xs-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xs-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-xs-down,
.cb-btn-small-xs-down {
    padding: 7px 23px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .cb-btn-light-sm {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-sm:hover, .cb-btn-light-sm.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-sm:focus, .cb-btn-light-sm.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-sm.cb-disabled, .cb-btn-light-sm:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-sm:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-primary:hover, .cb-btn-light-sm.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-primary:focus, .cb-btn-light-sm.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-disabled, .cb-btn-light-sm.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-sm.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-sm.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-black:hover, .cb-btn-light-sm.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-sm.cb-btn-black:focus, .cb-btn-light-sm.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-sm.cb-btn-black.cb-disabled, .cb-btn-light-sm.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-yellow:hover, .cb-btn-light-sm.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-sm.cb-btn-yellow:focus, .cb-btn-light-sm.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-sm.cb-btn-yellow.cb-disabled, .cb-btn-light-sm.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-sm.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-sm,
.cb-btn-small-sm {
    padding: 7px 23px;
  }
}
@media (min-width: 768px) {
  .cb-btn-light-sm-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-up:hover, .cb-btn-light-sm-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-sm-up:focus, .cb-btn-light-sm-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-sm-up.cb-disabled, .cb-btn-light-sm-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-primary:hover, .cb-btn-light-sm-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-primary:focus, .cb-btn-light-sm-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-primary.cb-disabled, .cb-btn-light-sm-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-sm-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-sm-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-sm-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-sm-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-sm-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-black:hover, .cb-btn-light-sm-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-black:focus, .cb-btn-light-sm-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-sm-up.cb-btn-black.cb-disabled, .cb-btn-light-sm-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-yellow:hover, .cb-btn-light-sm-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-sm-up.cb-btn-yellow:focus, .cb-btn-light-sm-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-yellow.cb-disabled, .cb-btn-light-sm-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-sm-up,
.cb-btn-small-sm-up {
    padding: 7px 23px;
  }
}
@media (max-width: 1023.98px) {
  .cb-btn-light-sm-down {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-down:hover, .cb-btn-light-sm-down.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-sm-down:focus, .cb-btn-light-sm-down.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-sm-down.cb-disabled, .cb-btn-light-sm-down:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-primary:hover, .cb-btn-light-sm-down.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-primary:focus, .cb-btn-light-sm-down.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-primary.cb-disabled, .cb-btn-light-sm-down.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-sm-down.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-sm-down.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-sm-down.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-sm-down.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-sm-down.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-black:hover, .cb-btn-light-sm-down.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-black:focus, .cb-btn-light-sm-down.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-sm-down.cb-btn-black.cb-disabled, .cb-btn-light-sm-down.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-yellow:hover, .cb-btn-light-sm-down.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-sm-down.cb-btn-yellow:focus, .cb-btn-light-sm-down.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-yellow.cb-disabled, .cb-btn-light-sm-down.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-sm-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-sm-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-sm-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-sm-down,
.cb-btn-small-sm-down {
    padding: 7px 23px;
  }
}
@media (min-width: 1024px) and (max-width: 1247.98px) {
  .cb-btn-light-md {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-md:hover, .cb-btn-light-md.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-md:focus, .cb-btn-light-md.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-md.cb-disabled, .cb-btn-light-md:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-md:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-primary:hover, .cb-btn-light-md.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-primary:focus, .cb-btn-light-md.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-md.cb-btn-primary.cb-disabled, .cb-btn-light-md.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-md.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-md.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-black:hover, .cb-btn-light-md.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-md.cb-btn-black:focus, .cb-btn-light-md.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-md.cb-btn-black.cb-disabled, .cb-btn-light-md.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-yellow:hover, .cb-btn-light-md.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-md.cb-btn-yellow:focus, .cb-btn-light-md.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-md.cb-btn-yellow.cb-disabled, .cb-btn-light-md.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-md.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-md,
.cb-btn-small-md {
    padding: 7px 23px;
  }
}
@media (min-width: 1024px) {
  .cb-btn-light-md-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-md-up:hover, .cb-btn-light-md-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-md-up:focus, .cb-btn-light-md-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-md-up.cb-disabled, .cb-btn-light-md-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-md-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-primary:hover, .cb-btn-light-md-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-primary:focus, .cb-btn-light-md-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-primary.cb-disabled, .cb-btn-light-md-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-md-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-md-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-md-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-md-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-md-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-black:hover, .cb-btn-light-md-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-black:focus, .cb-btn-light-md-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-md-up.cb-btn-black.cb-disabled, .cb-btn-light-md-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-yellow:hover, .cb-btn-light-md-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-md-up.cb-btn-yellow:focus, .cb-btn-light-md-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-yellow.cb-disabled, .cb-btn-light-md-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-md-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-md-up,
.cb-btn-small-md-up {
    padding: 7px 23px;
  }
}
@media (max-width: 1247.98px) {
  .cb-btn-light-md-down {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-md-down:hover, .cb-btn-light-md-down.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-md-down:focus, .cb-btn-light-md-down.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-md-down.cb-disabled, .cb-btn-light-md-down:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-md-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-primary:hover, .cb-btn-light-md-down.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-primary:focus, .cb-btn-light-md-down.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-primary.cb-disabled, .cb-btn-light-md-down.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-md-down.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-md-down.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-md-down.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-md-down.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-md-down.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-black:hover, .cb-btn-light-md-down.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-black:focus, .cb-btn-light-md-down.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-md-down.cb-btn-black.cb-disabled, .cb-btn-light-md-down.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-yellow:hover, .cb-btn-light-md-down.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-md-down.cb-btn-yellow:focus, .cb-btn-light-md-down.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-yellow.cb-disabled, .cb-btn-light-md-down.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-md-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-md-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-md-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-md-down,
.cb-btn-small-md-down {
    padding: 7px 23px;
  }
}
@media (min-width: 1248px) and (max-width: 1343.98px) {
  .cb-btn-light-lg {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-lg:hover, .cb-btn-light-lg.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-lg:focus, .cb-btn-light-lg.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-lg.cb-disabled, .cb-btn-light-lg:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-lg:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-primary:hover, .cb-btn-light-lg.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-primary:focus, .cb-btn-light-lg.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-disabled, .cb-btn-light-lg.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-lg.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-lg.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-black:hover, .cb-btn-light-lg.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-lg.cb-btn-black:focus, .cb-btn-light-lg.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-lg.cb-btn-black.cb-disabled, .cb-btn-light-lg.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-yellow:hover, .cb-btn-light-lg.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-lg.cb-btn-yellow:focus, .cb-btn-light-lg.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-lg.cb-btn-yellow.cb-disabled, .cb-btn-light-lg.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-lg.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-lg,
.cb-btn-small-lg {
    padding: 7px 23px;
  }
}
@media (min-width: 1248px) {
  .cb-btn-light-lg-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-up:hover, .cb-btn-light-lg-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-lg-up:focus, .cb-btn-light-lg-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-lg-up.cb-disabled, .cb-btn-light-lg-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-primary:hover, .cb-btn-light-lg-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-primary:focus, .cb-btn-light-lg-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-primary.cb-disabled, .cb-btn-light-lg-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-lg-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-lg-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-lg-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-lg-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-lg-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-black:hover, .cb-btn-light-lg-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-black:focus, .cb-btn-light-lg-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-lg-up.cb-btn-black.cb-disabled, .cb-btn-light-lg-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-yellow:hover, .cb-btn-light-lg-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-lg-up.cb-btn-yellow:focus, .cb-btn-light-lg-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-yellow.cb-disabled, .cb-btn-light-lg-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-lg-up,
.cb-btn-small-lg-up {
    padding: 7px 23px;
  }
}
@media (max-width: 1343.98px) {
  .cb-btn-light-lg-down {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-down:hover, .cb-btn-light-lg-down.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-lg-down:focus, .cb-btn-light-lg-down.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-lg-down.cb-disabled, .cb-btn-light-lg-down:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-primary:hover, .cb-btn-light-lg-down.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-primary:focus, .cb-btn-light-lg-down.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-primary.cb-disabled, .cb-btn-light-lg-down.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-lg-down.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-lg-down.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-lg-down.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-lg-down.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-lg-down.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-black:hover, .cb-btn-light-lg-down.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-black:focus, .cb-btn-light-lg-down.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-lg-down.cb-btn-black.cb-disabled, .cb-btn-light-lg-down.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-yellow:hover, .cb-btn-light-lg-down.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-lg-down.cb-btn-yellow:focus, .cb-btn-light-lg-down.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-yellow.cb-disabled, .cb-btn-light-lg-down.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-lg-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-lg-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-lg-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-lg-down,
.cb-btn-small-lg-down {
    padding: 7px 23px;
  }
}
@media (min-width: 1344px) and (max-width: 1439.98px) {
  .cb-btn-light-xl {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xl:hover, .cb-btn-light-xl.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xl:focus, .cb-btn-light-xl.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xl.cb-disabled, .cb-btn-light-xl:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xl:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-primary:hover, .cb-btn-light-xl.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-primary:focus, .cb-btn-light-xl.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-disabled, .cb-btn-light-xl.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xl.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xl.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-black:hover, .cb-btn-light-xl.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xl.cb-btn-black:focus, .cb-btn-light-xl.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xl.cb-btn-black.cb-disabled, .cb-btn-light-xl.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-yellow:hover, .cb-btn-light-xl.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-xl.cb-btn-yellow:focus, .cb-btn-light-xl.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xl.cb-btn-yellow.cb-disabled, .cb-btn-light-xl.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xl.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-xl,
.cb-btn-small-xl {
    padding: 7px 23px;
  }
}
@media (min-width: 1344px) {
  .cb-btn-light-xl-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-up:hover, .cb-btn-light-xl-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xl-up:focus, .cb-btn-light-xl-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xl-up.cb-disabled, .cb-btn-light-xl-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-primary:hover, .cb-btn-light-xl-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-primary:focus, .cb-btn-light-xl-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-primary.cb-disabled, .cb-btn-light-xl-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-xl-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xl-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xl-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xl-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xl-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-black:hover, .cb-btn-light-xl-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-black:focus, .cb-btn-light-xl-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xl-up.cb-btn-black.cb-disabled, .cb-btn-light-xl-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-yellow:hover, .cb-btn-light-xl-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-xl-up.cb-btn-yellow:focus, .cb-btn-light-xl-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-yellow.cb-disabled, .cb-btn-light-xl-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-xl-up,
.cb-btn-small-xl-up {
    padding: 7px 23px;
  }
}
@media (max-width: 1439.98px) {
  .cb-btn-light-xl-down {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-down:hover, .cb-btn-light-xl-down.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xl-down:focus, .cb-btn-light-xl-down.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xl-down.cb-disabled, .cb-btn-light-xl-down:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-primary:hover, .cb-btn-light-xl-down.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-primary:focus, .cb-btn-light-xl-down.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-primary.cb-disabled, .cb-btn-light-xl-down.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-xl-down.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-xl-down.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-xl-down.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-xl-down.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-xl-down.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-black:hover, .cb-btn-light-xl-down.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-black:focus, .cb-btn-light-xl-down.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-xl-down.cb-btn-black.cb-disabled, .cb-btn-light-xl-down.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-yellow:hover, .cb-btn-light-xl-down.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-xl-down.cb-btn-yellow:focus, .cb-btn-light-xl-down.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-yellow.cb-disabled, .cb-btn-light-xl-down.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-xl-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-xl-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-xl-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-xl-down,
.cb-btn-small-xl-down {
    padding: 7px 23px;
  }
}
@media (min-width: 1440px) and (max-width: 1727.98px) {
  .cb-btn-light-2xl {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl:hover, .cb-btn-light-2xl.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-2xl:focus, .cb-btn-light-2xl.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-2xl.cb-disabled, .cb-btn-light-2xl:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-primary:hover, .cb-btn-light-2xl.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-primary:focus, .cb-btn-light-2xl.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-disabled, .cb-btn-light-2xl.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-2xl.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-2xl.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-black:hover, .cb-btn-light-2xl.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-black:focus, .cb-btn-light-2xl.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-2xl.cb-btn-black.cb-disabled, .cb-btn-light-2xl.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-yellow:hover, .cb-btn-light-2xl.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-2xl.cb-btn-yellow:focus, .cb-btn-light-2xl.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-yellow.cb-disabled, .cb-btn-light-2xl.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-2xl,
.cb-btn-small-2xl {
    padding: 7px 23px;
  }
}
@media (min-width: 1440px) {
  .cb-btn-light-2xl-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-up:hover, .cb-btn-light-2xl-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-2xl-up:focus, .cb-btn-light-2xl-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-2xl-up.cb-disabled, .cb-btn-light-2xl-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-primary:hover, .cb-btn-light-2xl-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-primary:focus, .cb-btn-light-2xl-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-primary.cb-disabled, .cb-btn-light-2xl-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-2xl-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-2xl-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-2xl-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-2xl-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-2xl-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-black:hover, .cb-btn-light-2xl-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-black:focus, .cb-btn-light-2xl-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-2xl-up.cb-btn-black.cb-disabled, .cb-btn-light-2xl-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-yellow:hover, .cb-btn-light-2xl-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-2xl-up.cb-btn-yellow:focus, .cb-btn-light-2xl-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-yellow.cb-disabled, .cb-btn-light-2xl-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-2xl-up,
.cb-btn-small-2xl-up {
    padding: 7px 23px;
  }
}
@media (max-width: 1727.98px) {
  .cb-btn-light-2xl-down {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-down:hover, .cb-btn-light-2xl-down.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-2xl-down:focus, .cb-btn-light-2xl-down.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-2xl-down.cb-disabled, .cb-btn-light-2xl-down:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-primary:hover, .cb-btn-light-2xl-down.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-primary:focus, .cb-btn-light-2xl-down.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-primary.cb-disabled, .cb-btn-light-2xl-down.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-2xl-down.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-2xl-down.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-2xl-down.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-2xl-down.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-2xl-down.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-black:hover, .cb-btn-light-2xl-down.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-black:focus, .cb-btn-light-2xl-down.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-2xl-down.cb-btn-black.cb-disabled, .cb-btn-light-2xl-down.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-yellow:hover, .cb-btn-light-2xl-down.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-2xl-down.cb-btn-yellow:focus, .cb-btn-light-2xl-down.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-yellow.cb-disabled, .cb-btn-light-2xl-down.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-2xl-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-2xl-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-2xl-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-2xl-down,
.cb-btn-small-2xl-down {
    padding: 7px 23px;
  }
}
@media (min-width: 1728px) {
  .cb-btn-light-3xl {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl:hover, .cb-btn-light-3xl.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-3xl:focus, .cb-btn-light-3xl.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-3xl.cb-disabled, .cb-btn-light-3xl:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-primary:hover, .cb-btn-light-3xl.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-primary:focus, .cb-btn-light-3xl.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-disabled, .cb-btn-light-3xl.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-3xl.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-3xl.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-black:hover, .cb-btn-light-3xl.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-black:focus, .cb-btn-light-3xl.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-3xl.cb-btn-black.cb-disabled, .cb-btn-light-3xl.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-yellow:hover, .cb-btn-light-3xl.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-3xl.cb-btn-yellow:focus, .cb-btn-light-3xl.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-yellow.cb-disabled, .cb-btn-light-3xl.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-3xl,
.cb-btn-small-3xl {
    padding: 7px 23px;
  }
}
@media (min-width: 1728px) {
  .cb-btn-light-3xl-up {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl-up:hover, .cb-btn-light-3xl-up.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-3xl-up:focus, .cb-btn-light-3xl-up.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-3xl-up.cb-disabled, .cb-btn-light-3xl-up:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl-up:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-primary {
    background-color: #ffffff;
    color: #324dc7;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-primary:hover, .cb-btn-light-3xl-up.cb-btn-primary.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-primary:focus, .cb-btn-light-3xl-up.cb-btn-primary.cb-focus {
    color: #324dc7;
    border-color: #324dc7;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-primary.cb-disabled, .cb-btn-light-3xl-up.cb-btn-primary:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl-up.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #324dc7;
  }
  .cb-btn-light-3xl-up.cb-btn-primary.cb-palette-blue2 {
    color: #0077c8;
  }
  .cb-btn-light-3xl-up.cb-btn-primary.cb-palette-blue5 {
    color: #324dc7;
  }
  .cb-btn-light-3xl-up.cb-btn-primary.cb-palette-purple1 {
    color: #702f8a;
  }
  .cb-btn-light-3xl-up.cb-btn-primary.cb-palette-green1 {
    color: #3a913f;
  }
  .cb-btn-light-3xl-up.cb-btn-black {
    color: #ffffff;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-black:hover, .cb-btn-light-3xl-up.cb-btn-black.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-black:focus, .cb-btn-light-3xl-up.cb-btn-black.cb-focus {
    border-color: #ffffff;
    background-color: transparent;
    box-shadow: inset 0 0 0 2px #ffffff;
    text-decoration: underline;
  }
  .cb-btn-light-3xl-up.cb-btn-black.cb-disabled, .cb-btn-light-3xl-up.cb-btn-black:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl-up.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-yellow {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-yellow:hover, .cb-btn-light-3xl-up.cb-btn-yellow.cb-hover {
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: inset 0 0 0 1px #ffffff;
    color: #ffffff !important;
  }
  .cb-btn-light-3xl-up.cb-btn-yellow:focus, .cb-btn-light-3xl-up.cb-btn-yellow.cb-focus {
    color: #1e1e1e;
    border-color: #1e1e1e;
    box-shadow: 0 0 0 3px #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-yellow.cb-disabled, .cb-btn-light-3xl-up.cb-btn-yellow:disabled {
    text-decoration: none !important;
    background-color: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
  .cb-btn-light-3xl-up.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
    color: #ffffff;
  }
  .cb-btn-light-3xl-up.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-up.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
    color: #1e1e1e;
  }
  .cb-btn-sm-3xl-up,
.cb-btn-small-3xl-up {
    padding: 7px 23px;
  }
}
.cb-btn-light-3xl-down {
  color: #ffffff;
  border-color: #ffffff;
}
.cb-btn-light-3xl-down:hover, .cb-btn-light-3xl-down.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
}
.cb-btn-light-3xl-down:focus, .cb-btn-light-3xl-down.cb-focus {
  border-color: #ffffff;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffffff;
  text-decoration: underline;
}
.cb-btn-light-3xl-down.cb-disabled, .cb-btn-light-3xl-down:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-light-3xl-down:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-primary {
  background-color: #ffffff;
  color: #324dc7;
  border-color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-primary:hover, .cb-btn-light-3xl-down.cb-btn-primary.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-primary:focus, .cb-btn-light-3xl-down.cb-btn-primary.cb-focus {
  color: #324dc7;
  border-color: #324dc7;
  box-shadow: 0 0 0 3px #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-primary.cb-disabled, .cb-btn-light-3xl-down.cb-btn-primary:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-light-3xl-down.cb-btn-primary:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-primary:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-primary:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-primary:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
  color: #324dc7;
}
.cb-btn-light-3xl-down.cb-btn-primary.cb-palette-blue2 {
  color: #0077c8;
}
.cb-btn-light-3xl-down.cb-btn-primary.cb-palette-blue5 {
  color: #324dc7;
}
.cb-btn-light-3xl-down.cb-btn-primary.cb-palette-purple1 {
  color: #702f8a;
}
.cb-btn-light-3xl-down.cb-btn-primary.cb-palette-green1 {
  color: #3a913f;
}
.cb-btn-light-3xl-down.cb-btn-black {
  color: #ffffff;
  border-color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-black:hover, .cb-btn-light-3xl-down.cb-btn-black.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-black:focus, .cb-btn-light-3xl-down.cb-btn-black.cb-focus {
  border-color: #ffffff;
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #ffffff;
  text-decoration: underline;
}
.cb-btn-light-3xl-down.cb-btn-black.cb-disabled, .cb-btn-light-3xl-down.cb-btn-black:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4);
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-light-3xl-down.cb-btn-black:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-black:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-black:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-black:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-yellow {
  background-color: #ffffff;
  color: #1e1e1e;
  border-color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-yellow:hover, .cb-btn-light-3xl-down.cb-btn-yellow.cb-hover {
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 0 1px #ffffff;
  color: #ffffff !important;
}
.cb-btn-light-3xl-down.cb-btn-yellow:focus, .cb-btn-light-3xl-down.cb-btn-yellow.cb-focus {
  color: #1e1e1e;
  border-color: #1e1e1e;
  box-shadow: 0 0 0 3px #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-yellow.cb-disabled, .cb-btn-light-3xl-down.cb-btn-yellow:disabled {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.4) !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-light-3xl-down.cb-btn-yellow:not(.cb-btn-pulse):hover:not(:focus):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-yellow:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #ffffff;
}
.cb-btn-light-3xl-down.cb-btn-yellow:not(.cb-btn-pulse):focus:not(:hover):not(:disabled):not(.cb-disabled), .cb-btn-light-3xl-down.cb-btn-yellow:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled) {
  color: #1e1e1e;
}

.cb-btn-sm-3xl-down,
.cb-btn-small-3xl-down {
  padding: 7px 23px;
}

.cb-btn-tag {
  color: #505050;
  font-weight: 500;
  border-radius: 8px;
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  padding: 7px 11px;
}
.cb-btn-tag .cb-glyph,
.cb-btn-tag .cb-icon {
  font-size: 0.75rem;
  line-height: 2em;
}
.cb-btn-tag:hover, .cb-btn-tag.cb-hover {
  box-shadow: inset 0 0 0 1px #505050;
  border-color: #505050;
  text-decoration: none;
}
.cb-btn-tag:hover span, .cb-btn-tag.cb-hover span {
  text-decoration: none;
}
.cb-btn-tag:focus, .cb-btn-tag.cb-focus {
  text-decoration: underline;
  box-shadow: 0 0 1px 2px #505050;
  border-color: #505050;
  background-color: #f0f0f0;
}
.cb-btn-tag:not(.cb-btn-pulse):focus:not(:disabled):not(.cb-disabled), .cb-btn-tag:not(.cb-btn-pulse).cb-focus:not(:disabled):not(.cb-disabled), .cb-btn-tag:not(.cb-btn-pulse):hover:not(:disabled):not(.cb-disabled), .cb-btn-tag:not(.cb-btn-pulse).cb-hover:not(:disabled):not(.cb-disabled) {
  color: #505050;
}
.cb-btn-tag.cb-disabled, .cb-btn-tag:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-tag.cb-disabled span, .cb-btn-tag:disabled span {
  text-decoration: none !important;
}
.cb-btn-tag.cb-btn-sm, .cb-btn-tag.cb-btn-small {
  padding: 2px 8px;
  font-size: 0.75rem;
  line-height: 1.5em;
  border-radius: 4px;
  font-weight: 400;
}
.cb-btn-tag.cb-btn-sm .cb-glyph,
.cb-btn-tag.cb-btn-sm .cb-icon, .cb-btn-tag.cb-btn-small .cb-glyph,
.cb-btn-tag.cb-btn-small .cb-icon {
  font-size: 0.75rem !important;
  line-height: 1em !important;
}

.cb-btn-square {
  border-radius: 8px;
  font-size: 1rem;
  width: 3em;
  height: 3rem;
  line-height: 1em;
  padding: 0;
}
.cb-btn-square.cb-btn-sm, .cb-btn-square.cb-btn-small {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-btn-square:focus, .cb-btn-square.cb-focus {
  text-decoration: none;
}

.cb-btn-square.cb-btn-close {
  border: transparent;
  width: 2em;
  height: 2rem;
  font-size: 1rem;
  line-height: 1em;
  padding: 0;
}
.cb-btn-square.cb-btn-close.cb-btn-sm, .cb-btn-square.cb-btn-close.cb-btn-small {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-btn-square.cb-btn-close.cb-disabled, .cb-btn-square.cb-btn-close:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
  color: #888888 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-square.cb-btn-close.cb-disabled span, .cb-btn-square.cb-btn-close:disabled span {
  text-decoration: none !important;
}
.cb-btn-square.cb-btn-close:focus, .cb-btn-square.cb-btn-close.cb-focus {
  background-color: transparent !important;
}
.cb-btn-square.cb-btn-close.cb-white-color {
  background: transparent;
}
.cb-btn-square.cb-btn-close.cb-white-color:hover {
  background-color: #1e1e1e !important;
}

.cb-btn-circle {
  font-size: 1rem;
  line-height: 1.5em;
  border-radius: 50%;
  width: 3em;
  height: 3rem;
  padding: 0;
}
.cb-btn-circle.cb-btn-sm, .cb-btn-circle.cb-btn-small {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-btn-circle:focus, .cb-btn-circle.cb-focus {
  text-decoration: none;
}

.cb-btn-floating {
  border-color: transparent;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  color: #1e1e1e;
  background-color: #ffffff;
}
.cb-btn-floating:hover:not(:disabled), .cb-btn-floating:hover:not(.cb-disabled), .cb-btn-floating.cb-hover:not(:disabled), .cb-btn-floating.cb-hover:not(.cb-disabled) {
  background-color: #f0f0f0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
}
.cb-btn-floating:focus:not(:disabled), .cb-btn-floating:focus:not(.cb-disabled), .cb-btn-floating.cb-focus:not(:disabled), .cb-btn-floating.cb-focus:not(.cb-disabled) {
  box-shadow: inset 0 0 1px 2px #505050;
}

.cb-btn-greyscale {
  border-color: #d9d9d9;
  color: #1e1e1e;
  background-color: #ffffff;
}
.cb-btn-greyscale:hover:not(:disabled):not(.cb-disabled), .cb-btn-greyscale.cb-hover:not(:disabled):not(.cb-disabled) {
  color: #1e1e1e;
  background-color: #f0f0f0;
  box-shadow: none;
}
.cb-btn-greyscale:focus:not(:disabled):not(.cb-disabled), .cb-btn-greyscale.cb-focus:not(:disabled):not(.cb-disabled) {
  color: #1e1e1e;
  box-shadow: inset 0 0 1px 2px #505050;
}
.cb-btn-greyscale.cb-white-color:focus:not(:disabled):not(.cb-disabled), .cb-btn-greyscale.cb-white-color.cb-focus:not(:disabled):not(.cb-disabled) {
  box-shadow: inset 0 0 1px 2px #ffffff;
}

.cb-btn-naked {
  background-color: transparent;
  border-color: transparent;
  color: #324dc7;
}
.cb-btn-naked:hover, .cb-btn-naked.cb-hover {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  box-shadow: none;
}
.cb-btn-naked:focus, .cb-btn-naked.cb-focus {
  border-color: #324dc7;
  background-color: transparent;
  box-shadow: inset 0 0 0 3px #324dc7;
}
.cb-btn-naked.cb-disabled, .cb-btn-naked:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-naked.cb-disabled span, .cb-btn-naked:disabled span {
  text-decoration: none !important;
}
.cb-btn-naked.cb-btn-light {
  color: #ffffff;
}
.cb-btn-naked.cb-btn-light:focus, .cb-btn-naked.cb-btn-light.cb-focus {
  border-color: #ffffff;
  background-color: transparent;
  box-shadow: inset 0 0 0 3px #ffffff;
}
.cb-btn-naked.cb-btn-black {
  color: #1e1e1e;
  background-color: transparent;
  border-color: transparent;
}
.cb-btn-naked.cb-btn-black.cb-disabled, .cb-btn-naked.cb-btn-black:disabled {
  opacity: 1;
  text-decoration: none !important;
  background-color: transparent;
  border-color: transparent;
  color: #b2b2b2 !important;
  cursor: not-allowed;
  box-shadow: none;
}
.cb-btn-naked.cb-btn-black.cb-disabled span, .cb-btn-naked.cb-btn-black:disabled span {
  text-decoration: none !important;
}
.cb-btn-naked.cb-btn-black:hover, .cb-btn-naked.cb-btn-black.cb-hover {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
  box-shadow: none;
}
.cb-btn-naked.cb-btn-black:focus, .cb-btn-naked.cb-btn-black.cb-focus {
  border-color: #1e1e1e;
  background-color: transparent;
  box-shadow: inset 0 0 0 3px #1e1e1e;
}
.cb-btn-naked.cb-no-padding {
  border-radius: 0;
}
.cb-btn-naked.cb-no-padding:focus, .cb-btn-naked.cb-no-padding.cb-focus {
  position: relative;
  border-color: transparent !important;
  background-color: transparent;
  box-shadow: none !important;
  outline: none !important;
}
.cb-btn-naked.cb-no-padding:focus::after, .cb-btn-naked.cb-no-padding.cb-focus::after {
  position: absolute;
  content: "";
  display: block;
  width: calc(100% + 8px);
  height: 100%;
  top: 0;
  left: -4px;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px #324dc7;
}
.cb-btn-naked.cb-no-padding.cb-no-outline:focus, .cb-btn-naked.cb-no-padding.cb-no-outline.cb-focus {
  position: relative;
  border-color: transparent !important;
  background-color: transparent;
  box-shadow: none !important;
  outline: none !important;
}
.cb-btn-naked.cb-no-padding.cb-no-outline:focus::after, .cb-btn-naked.cb-no-padding.cb-no-outline.cb-focus::after {
  position: absolute;
  content: "";
  display: block;
  width: calc(100% + 24px);
  height: calc(100% + 12px);
  top: -6px;
  left: -12px;
  border-radius: 999px;
  box-shadow: inset 0 0 0 3px #324dc7;
}

a.cb-btn-square, a.cb-btn-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.cb-btn-block {
  display: block;
  width: 100%;
}

input[type=submit].cb-btn-block,
input[type=reset].cb-btn-block,
input[type=button].cb-btn-block {
  width: 100%;
}

.cb-btn-effect1 {
  background-color: rgba(255, 255, 255, 0.25);
}

.cb-btn-row {
  font-size: 0;
  flex-flow: wrap;
  margin-left: -16px;
  margin-top: -16px;
}
.cb-btn-row .cb-btn {
  flex-shrink: 0;
  margin-top: 16px;
  margin-left: 16px;
}
.cb-btn-row .cb-btn + .cb-btn {
  margin-left: 16px;
}
.cb-btn-row.cb-btn-row-sm {
  margin-left: -8px;
  margin-top: -8px;
}
.cb-btn-row.cb-btn-row-sm .cb-btn {
  font-family: "Roboto", sans-serif;
  margin-top: 8px;
  margin-left: 8px;
}
.cb-btn-row.cb-btn-row-sm .cb-btn:not(.cb-btn-tag) {
  padding: 7px 23px;
}
.cb-btn-row.cb-btn-row-sm .cb-btn.cb-btn-square {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-btn-row.cb-btn-row-sm .cb-btn.cb-btn-square.cb-btn-close {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-btn-row.cb-btn-row-sm .cb-btn.cb-btn-circle {
  width: 1.5rem;
  height: 1.5rem;
  line-height: 0.875em;
  font-size: 0.875rem;
  padding: 0;
}
.cb-btn-row.cb-btn-row-sm .cb-btn + .cb-btn {
  margin-left: 8px;
}

.cb-btn-row.cb-tag-set {
  font-size: 1rem;
}
.cb-btn-row.cb-tag-set > .cb-glyph,
.cb-btn-row.cb-tag-set > .cb-icon {
  flex-shrink: 0;
  margin-top: 16px;
  margin-left: 16px;
}
.cb-btn-row.cb-tag-set .cb-glyph,
.cb-btn-row.cb-tag-set .cb-icon {
  font-size: 1em;
}
.cb-btn-row.cb-tag-set.cb-btn-row-sm > .cb-glyph,
.cb-btn-row.cb-tag-set.cb-btn-row-sm > .cb-icon {
  margin-top: 8px;
}

.cb-btn-pulse {
  position: relative;
  color: transparent;
}
.cb-btn-pulse .cb-glyph,
.cb-btn-pulse .cb-icon {
  display: none;
}
.cb-btn-pulse .cb-btn-pulse-dots {
  position: absolute;
  top: 20px;
  left: 46%;
}
.cb-btn-pulse .cb-btn-pulse-dots .cb-btn-dots {
  background-color: transparent;
  border-radius: 50%;
  color: #324dc7;
  height: 6px;
  width: 6px;
  animation: dotPulse 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 0 #324dc7;
  left: -9999px;
  position: relative;
}
.cb-btn-pulse.cb-btn-sm .cb-btn-pulse-dots, .cb-btn-pulse.cb-btn-small .cb-btn-pulse-dots {
  top: 16px;
}
.cb-btn-pulse.cb-btn-lg .cb-btn-pulse-dots {
  top: 28px;
}
.cb-btn-pulse.cb-btn-light .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseWhite 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 0 #ffffff;
}
.cb-btn-pulse.cb-btn-naked .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulse 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 0 #324dc7;
}
.cb-btn-pulse.cb-btn-naked.cb-btn-light {
  color: transparent;
}
.cb-btn-pulse.cb-btn-naked.cb-btn-light .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseWhite 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 0 #ffffff;
}
.cb-btn-pulse.cb-btn-black .cb-btn-pulse-dots .cb-btn-dots, .cb-btn-pulse.cb-btn-yellow .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlack 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #1e1e1e, 9999px 0 0 0 #1e1e1e, 10011px 0 0 0 #1e1e1e;
}
.cb-btn-pulse.cb-btn-black.cb-btn-light {
  color: transparent;
}
.cb-btn-pulse.cb-btn-black.cb-btn-light .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseWhite 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 0 #ffffff;
}
.cb-btn-pulse.cb-btn-black.cb-btn-light:hover, .cb-btn-pulse.cb-btn-black.cb-btn-light.cb-hover, .cb-btn-pulse.cb-btn-black.cb-btn-light:focus, .cb-btn-pulse.cb-btn-black.cb-btn-light.cb-focus {
  text-decoration: none;
  cursor: default;
  color: transparent !important;
}
.cb-btn-pulse.cb-btn-black.cb-palette-blue5 {
  color: transparent;
}
.cb-btn-pulse.cb-btn-black.cb-palette-blue5 .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlue5 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 0 #324dc7;
}
.cb-btn-pulse.cb-btn-black.cb-palette-blue2 {
  color: transparent;
}
.cb-btn-pulse.cb-btn-black.cb-palette-blue2 .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlue2 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #0077c8, 9999px 0 0 0 #0077c8, 10011px 0 0 0 #0077c8;
}
.cb-btn-pulse.cb-btn-black.cb-palette-purple1 {
  color: transparent;
}
.cb-btn-pulse.cb-btn-black.cb-palette-purple1 .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulsePurple1 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #702f8a, 9999px 0 0 0 #702f8a, 10011px 0 0 0 #702f8a;
}
.cb-btn-pulse:hover, .cb-btn-pulse.cb-hover, .cb-btn-pulse:focus, .cb-btn-pulse.cb-focus {
  text-decoration: none;
  cursor: default;
  color: transparent !important;
}
.cb-btn-pulse:hover, .cb-btn-pulse.cb-hover {
  cursor: pointer;
}
.cb-btn-pulse.cb-btn-primary .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseWhite 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 0 #ffffff;
}
.cb-btn-pulse.cb-btn-primary.cb-btn-light {
  color: transparent;
}
.cb-btn-pulse.cb-btn-primary.cb-btn-light .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlue5 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 0 #324dc7;
}
.cb-btn-pulse.cb-btn-primary.cb-btn-light:focus, .cb-btn-pulse.cb-btn-primary.cb-btn-light.cb-focus {
  text-decoration: none;
  cursor: default;
  color: transparent !important;
}
.cb-btn-pulse.cb-btn-primary.cb-btn-light:focus .cb-btn-pulse-dots .cb-btn-dots, .cb-btn-pulse.cb-btn-primary.cb-btn-light.cb-focus .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlue5 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 0 #324dc7;
}
.cb-btn-pulse.cb-btn-primary.cb-btn-light:hover, .cb-btn-pulse.cb-btn-primary.cb-btn-light.cb-hover {
  text-decoration: none;
  cursor: default;
  color: transparent !important;
}
.cb-btn-pulse.cb-btn-primary.cb-btn-light:hover .cb-btn-pulse-dots .cb-btn-dots, .cb-btn-pulse.cb-btn-primary.cb-btn-light.cb-hover .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseWhite 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 0 #ffffff;
}
.cb-btn-pulse.cb-btn-yellow.cb-btn-light {
  color: transparent;
}
.cb-btn-pulse.cb-btn-yellow.cb-btn-light .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlack 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #1e1e1e, 9999px 0 0 0 #1e1e1e, 10011px 0 0 0 #1e1e1e;
}
.cb-btn-pulse.cb-btn-yellow.cb-btn-light:focus, .cb-btn-pulse.cb-btn-yellow.cb-btn-light.cb-focus {
  text-decoration: none;
  cursor: default;
  color: transparent !important;
}
.cb-btn-pulse.cb-btn-yellow.cb-btn-light:focus .cb-btn-pulse-dots .cb-btn-dots, .cb-btn-pulse.cb-btn-yellow.cb-btn-light.cb-focus .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseBlack 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #1e1e1e, 9999px 0 0 0 #1e1e1e, 10011px 0 0 0 #1e1e1e;
}
.cb-btn-pulse.cb-btn-yellow.cb-btn-light:hover, .cb-btn-pulse.cb-btn-yellow.cb-btn-light.cb-hover {
  text-decoration: none;
  cursor: default;
  color: transparent !important;
}
.cb-btn-pulse.cb-btn-yellow.cb-btn-light:hover .cb-btn-pulse-dots .cb-btn-dots, .cb-btn-pulse.cb-btn-yellow.cb-btn-light.cb-hover .cb-btn-pulse-dots .cb-btn-dots {
  animation: dotPulseWhite 1.5s infinite linear;
  box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 0 #ffffff;
}

@keyframes dotPulse {
  0% {
    box-shadow: 9987px 0 0 -5px #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 2px #324dc7;
  }
  25% {
    box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 2px #324dc7, 10011px 0 0 0 #324dc7;
  }
  50% {
    box-shadow: 9987px 0 0 2px #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 -5px #324dc7;
  }
  75% {
    box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 -5px #324dc7, 10011px 0 0 0 #324dc7;
  }
  100% {
    box-shadow: 9987px 0 0 -5px #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 2px #324dc7;
  }
}
@keyframes dotPulseWhite {
  0% {
    box-shadow: 9987px 0 0 -5px #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 2px #ffffff;
  }
  25% {
    box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 2px #ffffff, 10011px 0 0 0 #ffffff;
  }
  50% {
    box-shadow: 9987px 0 0 2px #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 -5px #ffffff;
  }
  75% {
    box-shadow: 9987px 0 0 0 #ffffff, 9999px 0 0 -5px #ffffff, 10011px 0 0 0 #ffffff;
  }
  100% {
    box-shadow: 9987px 0 0 -5px #ffffff, 9999px 0 0 0 #ffffff, 10011px 0 0 2px #ffffff;
  }
}
@keyframes dotPulseBlack {
  0% {
    box-shadow: 9987px 0 0 -5px #1e1e1e, 9999px 0 0 0 #1e1e1e, 10011px 0 0 2px #1e1e1e;
  }
  25% {
    box-shadow: 9987px 0 0 0 #1e1e1e, 9999px 0 0 2px #1e1e1e, 10011px 0 0 0 #1e1e1e;
  }
  50% {
    box-shadow: 9987px 0 0 2px #1e1e1e, 9999px 0 0 0 #1e1e1e, 10011px 0 0 -5px #1e1e1e;
  }
  75% {
    box-shadow: 9987px 0 0 0 #1e1e1e, 9999px 0 0 -5px #1e1e1e, 10011px 0 0 0 #1e1e1e;
  }
  100% {
    box-shadow: 9987px 0 0 -5px #1e1e1e, 9999px 0 0 0 #1e1e1e, 10011px 0 0 2px #1e1e1e;
  }
}
@keyframes dotPulseBlue5 {
  0% {
    box-shadow: 9987px 0 0 -5px #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 2px #324dc7;
  }
  25% {
    box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 2px #324dc7, 10011px 0 0 0 #324dc7;
  }
  50% {
    box-shadow: 9987px 0 0 2px #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 -5px #324dc7;
  }
  75% {
    box-shadow: 9987px 0 0 0 #324dc7, 9999px 0 0 -5px #324dc7, 10011px 0 0 0 #324dc7;
  }
  100% {
    box-shadow: 9987px 0 0 -5px #324dc7, 9999px 0 0 0 #324dc7, 10011px 0 0 2px #324dc7;
  }
}
@keyframes dotPulseBlue2 {
  0% {
    box-shadow: 9987px 0 0 -5px #0077c8, 9999px 0 0 0 #0077c8, 10011px 0 0 2px #0077c8;
  }
  25% {
    box-shadow: 9987px 0 0 0 #0077c8, 9999px 0 0 2px #0077c8, 10011px 0 0 0 #0077c8;
  }
  50% {
    box-shadow: 9987px 0 0 2px #0077c8, 9999px 0 0 0 #0077c8, 10011px 0 0 -5px #0077c8;
  }
  75% {
    box-shadow: 9987px 0 0 0 #0077c8, 9999px 0 0 -5px #0077c8, 10011px 0 0 0 #0077c8;
  }
  100% {
    box-shadow: 9987px 0 0 -5px #0077c8, 9999px 0 0 0 #0077c8, 10011px 0 0 2px #0077c8;
  }
}
@keyframes dotPulsePurple1 {
  0% {
    box-shadow: 9987px 0 0 -5px #702f8a, 9999px 0 0 0 #702f8a, 10011px 0 0 2px #702f8a;
  }
  25% {
    box-shadow: 9987px 0 0 0 #702f8a, 9999px 0 0 2px #702f8a, 10011px 0 0 0 #702f8a;
  }
  50% {
    box-shadow: 9987px 0 0 2px #702f8a, 9999px 0 0 0 #702f8a, 10011px 0 0 -5px #702f8a;
  }
  75% {
    box-shadow: 9987px 0 0 0 #702f8a, 9999px 0 0 -5px #702f8a, 10011px 0 0 0 #702f8a;
  }
  100% {
    box-shadow: 9987px 0 0 -5px #702f8a, 9999px 0 0 0 #702f8a, 10011px 0 0 2px #0077c8;
  }
}