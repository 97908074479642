.cb-input input,
.cb-input textarea {
  display: block;
  width: 100%;
  line-height: 1.5em;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding: 11px 15px;
  border-radius: 8px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-input input::placeholder,
.cb-input textarea::placeholder {
  color: #888888;
  font-size: 1rem;
  line-height: 1.5;
}
.cb-input input:not(:disabled):hover,
.cb-input textarea:not(:disabled):hover {
  cursor: pointer;
}
.cb-input input:not(:disabled):focus,
.cb-input textarea:not(:disabled):focus {
  outline: 0;
  cursor: default;
  border: 1px solid #324dc7;
  box-shadow: inset 0px 0px 1px 0.5px rgb(48, 73, 207);
}
.cb-input input:disabled,
.cb-input textarea:disabled {
  color: #888888;
  cursor: not-allowed;
  -webkit-text-fill-color: #888888; /* Override iOS / Android font color change */
  -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
}
.cb-input.cb-disabled label,
.cb-input.cb-disabled .cb-glyph,
.cb-input.cb-disabled .cb-icon {
  color: #888888 !important;
}
.cb-input .cb-glyph,
.cb-input .cb-icon {
  font-size: 1em;
}
.cb-input .cb-input-icon-right,
.cb-input .cb-input-icon-left {
  position: relative;
}
.cb-input .cb-input-icon-right .cb-glyph,
.cb-input .cb-input-icon-right .cb-icon,
.cb-input .cb-input-icon-left .cb-glyph,
.cb-input .cb-input-icon-left .cb-icon {
  position: absolute;
  color: #505050;
  top: 36px;
  padding: 4px 0;
}
.cb-input .cb-input-icon-left input {
  padding-left: 39px;
}
.cb-input .cb-input-icon-left .cb-glyph,
.cb-input .cb-input-icon-left .cb-icon {
  left: 16px;
}
.cb-input .cb-input-icon-right input {
  padding-right: 40px;
}
.cb-input .cb-input-icon-right .cb-glyph,
.cb-input .cb-input-icon-right .cb-icon {
  right: 16px;
}
.cb-input.cb-input-no-label .cb-input-icon-left .cb-glyph,
.cb-input.cb-input-no-label .cb-input-icon-left .cb-icon,
.cb-input.cb-input-no-label .cb-input-icon-right .cb-glyph,
.cb-input.cb-input-no-label .cb-input-icon-right .cb-icon {
  top: 12px;
}
.cb-input.cb-btn-input {
  position: relative;
}
.cb-input.cb-btn-input input {
  padding-right: 48px;
  z-index: 1;
}
.cb-input.cb-btn-input .cb-btn {
  border: transparent;
  font-size: 1rem;
  line-height: 1em;
  width: 2em;
  height: 2em;
  padding: 0;
  position: absolute;
  right: 8px;
  top: 32px;
  z-index: 2;
}
.cb-input.cb-btn-input .cb-btn .cb-glyph,
.cb-input.cb-btn-input .cb-btn .cb-icon {
  position: relative;
  top: 0;
  left: 0;
  color: #505050;
  transition: color 0.35s ease;
}
.cb-input.cb-btn-input .cb-btn:hover {
  background: transparent;
  box-shadow: none;
}
.cb-input.cb-btn-input .cb-btn:disabled:hover {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}
.cb-input.cb-btn-input .cb-btn:focus {
  box-shadow: none;
  background: transparent;
}
.cb-input.cb-btn-input .cb-btn:focus .cb-glyph,
.cb-input.cb-btn-input .cb-btn:focus .cb-icon {
  color: #324dc7;
}
.cb-input.cb-btn-input input[aria-label] + .cb-btn {
  top: 8px;
}
.cb-input.cb-btn-input.cb-password-input .cb-btn:focus .cb-glyph,
.cb-input.cb-btn-input.cb-password-input .cb-btn:focus .cb-icon {
  color: #1e1e1e;
}
.cb-input.cb-btn-input.cb-input-condensed .cb-btn {
  width: 1.5em;
  height: 1.5em;
}
.cb-input.cb-btn-input.cb-input-condensed .cb-btn .cb-glyph,
.cb-input.cb-btn-input.cb-input-condensed .cb-btn .cb-icon {
  font-size: 0.875rem !important;
}
.cb-input.cb-btn-input.cb-input-no-label .cb-btn {
  top: 2px;
}
.cb-input.cb-btn-input.cb-disabled .cb-btn {
  background: transparent;
}
.cb-input.cb-btn-input.cb-disabled .cb-btn .cb-glyph,
.cb-input.cb-btn-input.cb-disabled .cb-btn .cb-icon {
  color: #888888;
}
.cb-input.cb-input-condensed {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}
.cb-input.cb-input-condensed input,
.cb-input.cb-input-condensed textarea {
  padding: 7px 15px;
}
.cb-input.cb-input-condensed .cb-input-icon-right .cb-glyph,
.cb-input.cb-input-condensed .cb-input-icon-right .cb-icon,
.cb-input.cb-input-condensed .cb-input-icon-left .cb-glyph,
.cb-input.cb-input-condensed .cb-input-icon-left .cb-icon {
  top: 33px;
}
.cb-input.cb-input-condensed .cb-input-icon-left input {
  padding-left: 39px;
}
.cb-input.cb-input-condensed .cb-input-icon-left .cb-glyph,
.cb-input.cb-input-condensed .cb-input-icon-left .cb-icon {
  left: 16px;
}
.cb-input.cb-input-condensed .cb-input-icon-right input {
  padding-right: 40px;
}
.cb-input.cb-input-condensed .cb-input-icon-right .cb-glyph,
.cb-input.cb-input-condensed .cb-input-icon-right .cb-icon {
  right: 16px;
}
.cb-input.cb-input-condensed.cb-input-no-label .cb-input-icon-left .cb-glyph,
.cb-input.cb-input-condensed.cb-input-no-label .cb-input-icon-left .cb-icon,
.cb-input.cb-input-condensed.cb-input-no-label .cb-input-icon-right .cb-glyph,
.cb-input.cb-input-condensed.cb-input-no-label .cb-input-icon-right .cb-icon {
  top: 10px;
}
.cb-input.cb-input-condensed.cb-btn-input input {
  padding-right: 38px;
}
.cb-input.cb-input-condensed.cb-btn-input .cb-btn .cb-glyph,
.cb-input.cb-input-condensed.cb-btn-input .cb-btn .cb-icon {
  position: relative;
  top: 0;
  left: 0;
}
.cb-input.cb-input-condensed.cb-btn-input.cb-input-no-label .cb-btn {
  top: 2px;
}

.cb-input-helper {
  color: #505050;
  font-size: 0.75rem;
  line-height: 1.3333333333em;
  margin-top: 8px;
}

.cb-validation-error + .cb-input-helper,
.cb-validation-success + .cb-input-helper {
  width: calc(100% - 2.5em);
}

.cb-radio {
  display: flex;
}
.cb-radio label {
  cursor: pointer;
  align-items: flex-start;
}
.cb-radio input[type=radio] {
  position: relative;
  margin-left: 1px;
  margin-top: 1px;
  height: 22px;
  width: 22px;
  margin-right: 8px;
}
.cb-radio input[type=radio]:before {
  content: "";
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  left: -1px;
  top: -1px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  background-color: #ffffff;
  transition: all 0.25s ease-out;
  border: 1px solid #1e1e1e;
  border-radius: 50%;
  outline: none;
}
.cb-radio input[type=radio]::after {
  position: absolute;
  display: block;
  box-sizing: border-box;
  content: "";
  left: 11px;
  top: 11px;
  height: 0px;
  width: 0px;
  border-radius: 50%;
  background-color: #324dc7;
  border: 0 solid #324dc7;
  opacity: 1;
  transition: all 0.3s ease-out;
}
.cb-radio input[type=radio]:checked::before {
  border-color: #324dc7;
  border-width: 2px;
  background-color: #ffffff;
}
.cb-radio input[type=radio]:checked::after {
  width: 0;
  height: 0;
  border: 7px solid #324dc7;
  top: 4px;
  left: 4px;
}
.cb-radio input[type=radio]:hover:not(:checked)::before, .cb-radio input[type=radio]:focus:not(:checked)::before {
  border: 1px solid #324dc7;
  background: #f0f0f0;
}
.cb-radio .cb-label {
  margin-left: 8px;
}

.cb-checkbox {
  display: flex;
}
.cb-checkbox label {
  cursor: pointer;
  align-items: flex-start;
}
.cb-checkbox input[type=checkbox] {
  position: relative;
  margin-left: 1px;
  margin-top: 1px;
  height: 22px;
  width: 22px;
  margin-right: 8px;
}
.cb-checkbox input[type=checkbox]::before {
  content: "";
  cursor: pointer;
  position: absolute;
  left: -1px;
  top: -1px;
  height: 24px;
  width: 24px;
  min-width: 24px;
  background-color: #fff;
  border-radius: 4px;
  transition: all 0.25s ease-out;
  border: 1px solid #1e1e1e;
  outline: none;
}
.cb-checkbox input[type=checkbox]::after {
  position: absolute;
  content: "";
  left: 8px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 4px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
}
.cb-checkbox input[type=checkbox]:checked::before {
  border-color: #324dc7;
  background-color: #324dc7;
}
.cb-checkbox input[type=checkbox]:checked::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 8px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
.cb-checkbox input[type=checkbox]:hover:not(:checked), .cb-checkbox input[type=checkbox]:focus:not(:checked) {
  outline: none;
}
.cb-checkbox input[type=checkbox]:hover:not(:checked)::before, .cb-checkbox input[type=checkbox]:focus:not(:checked)::before {
  border: 1px solid #324dc7;
  background: #f0f0f0;
}
.cb-checkbox .cb-label {
  margin-left: 9px;
}

.cb-radio.cb-disabled label, .cb-radio.disabled label, fieldset[disabled] .cb-radio label,
.cb-checkbox.cb-disabled label,
.cb-checkbox.disabled label,
fieldset[disabled] .cb-checkbox label {
  color: #989795;
}
.cb-radio.cb-disabled input::before, .cb-radio.disabled input::before, fieldset[disabled] .cb-radio input::before,
.cb-checkbox.cb-disabled input::before,
.cb-checkbox.disabled input::before,
fieldset[disabled] .cb-checkbox input::before {
  cursor: not-allowed !important;
  border: 1px solid #d9d9d9 !important;
  background: #f0f0f0 !important;
}
.cb-radio.cb-disabled input[type=checkbox]:checked::after, .cb-radio.disabled input[type=checkbox]:checked::after, fieldset[disabled] .cb-radio input[type=checkbox]:checked::after,
.cb-checkbox.cb-disabled input[type=checkbox]:checked::after,
.cb-checkbox.disabled input[type=checkbox]:checked::after,
fieldset[disabled] .cb-checkbox input[type=checkbox]:checked::after {
  border-color: #d9d9d9;
}
.cb-radio.cb-disabled input[type=radio]:checked::after, .cb-radio.disabled input[type=radio]:checked::after, fieldset[disabled] .cb-radio input[type=radio]:checked::after,
.cb-checkbox.cb-disabled input[type=radio]:checked::after,
.cb-checkbox.disabled input[type=radio]:checked::after,
fieldset[disabled] .cb-checkbox input[type=radio]:checked::after {
  background: #d9d9d9;
  border-color: #d9d9d9;
  top: 4px;
  left: 4px;
}

.cb-checkbox + .cb-input-helper,
.cb-radio + .cb-input-helper {
  margin-top: 8px;
}

.cb-table td input {
  background: #d9d9d9;
  padding: 0 6px;
}

legend {
  font-size: 1.625rem;
  line-height: 1.2307692308em;
  padding: 0;
}
legend.cb-label {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  color: #505050;
  font-weight: 500;
}

.cb-toggles-btn {
  display: inline-flex;
  font-size: 0;
}
.cb-toggles-btn label {
  position: relative;
  padding: 0;
  margin: 0;
  width: 48px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.cb-toggles-btn label:hover {
  cursor: pointer;
}
.cb-toggles-btn label input[type=radio] {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  padding: 0;
}
.cb-toggles-btn label input[type=radio] + span:not(.cb-glyph):not(.cb-icon) {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  width: 48px;
  height: 48px;
  color: #1e1e1e;
  font-family: Roboto !important;
  font-weight: 700;
  font-size: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
}
.cb-toggles-btn label input[type=radio] + span:not(.cb-glyph):not(.cb-icon) + .cb-glyph, .cb-toggles-btn label input[type=radio] + span:not(.cb-glyph):not(.cb-icon) + .cb-icon {
  font-size: 1rem;
  width: 1em;
  height: 1em;
  z-index: 3;
}
.cb-toggles-btn label input[type=radio]:hover, .cb-toggles-btn label input[type=radio]:focus, .cb-toggles-btn label input[type=radio]:checked, .cb-toggles-btn label input[type=radio].cb-checked {
  outline: 0;
  box-shadow: none;
}
.cb-toggles-btn label input[type=radio]:hover + span:not(.cb-glyph):not(.cb-icon) {
  background-color: #f0f0f0;
}
.cb-toggles-btn label input[type=radio]:focus + span:not(.cb-glyph):not(.cb-icon) {
  background-color: #ffffff;
  border: 1px solid #1e1e1e;
}
.cb-toggles-btn label input[type=radio]:active:focus + span:not(.cb-glyph):not(.cb-icon) {
  background-color: #b2b2b2;
  border: 1px solid #d9d9d9;
}
.cb-toggles-btn label input[type=radio]:checked + span:not(.cb-glyph):not(.cb-icon) {
  background-color: #d9d9d9;
  text-decoration: underline;
}
.cb-toggles-btn label input[type=radio]:checked + span:not(.cb-glyph):not(.cb-icon) + .cb-glyph, .cb-toggles-btn label input[type=radio]:checked + span:not(.cb-glyph):not(.cb-icon) + .cb-icon {
  text-decoration: underline;
}
.cb-toggles-btn label input[type=radio][disabled] + span:not(.cb-glyph):not(.cb-icon), .cb-toggles-btn label input[type=radio].disabled + span:not(.cb-glyph):not(.cb-icon), fieldset[disabled] .cb-toggles-btn label input[type=radio] + span:not(.cb-glyph):not(.cb-icon) {
  background-color: #f0f0f0;
  color: #d9d9d9;
  cursor: not-allowed;
}
.cb-toggles-btn label input[type=radio][disabled] + span:not(.cb-glyph):not(.cb-icon) + .cb-glyph,
.cb-toggles-btn label input[type=radio][disabled] + span:not(.cb-glyph):not(.cb-icon) + .cb-icon, .cb-toggles-btn label input[type=radio].disabled + span:not(.cb-glyph):not(.cb-icon) + .cb-glyph,
.cb-toggles-btn label input[type=radio].disabled + span:not(.cb-glyph):not(.cb-icon) + .cb-icon, fieldset[disabled] .cb-toggles-btn label input[type=radio] + span:not(.cb-glyph):not(.cb-icon) + .cb-glyph,
fieldset[disabled] .cb-toggles-btn label input[type=radio] + span:not(.cb-glyph):not(.cb-icon) + .cb-icon {
  color: #d9d9d9;
  cursor: not-allowed;
}
.cb-toggles-btn label:first-of-type input[type=radio] + span:not(.cb-glyph):not(.cb-icon) {
  border-left: 1px solid #d9d9d9;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.cb-toggles-btn label:first-of-type input[type=radio]:focus + span:not(.cb-glyph):not(.cb-icon) {
  border-left: 1px solid #1e1e1e;
}
.cb-toggles-btn label:last-of-type input[type=radio] + span:not(.cb-glyph):not(.cb-icon) {
  border-right: 1px solid #d9d9d9;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cb-toggles-btn label:last-of-type input[type=radio]:focus + span:not(.cb-glyph):not(.cb-icon) {
  border-right: 1px solid #1e1e1e;
}

.cb-toggle-switch {
  display: inline-flex;
  justify-content: center;
  width: fit-content;
}
.cb-toggle-switch .cb-switch {
  display: inline-block;
  position: relative;
  outline: none;
}
.cb-toggle-switch .cb-switch .cb-switch-track {
  box-sizing: border-box;
  width: 48px;
  height: 24px;
  border: 1px solid;
  border-radius: 999px;
  background: #888888;
  border-color: #888888;
  transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
.cb-toggle-switch .cb-switch .cb-switch-wrap {
  left: -12px;
  right: auto;
  display: flex;
  position: absolute;
  top: -12px;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transform: translateX(0);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
.cb-toggle-switch .cb-switch .cb-switch-wrap .cb-switch-thumb {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 1;
  border: 11px solid #ffffff;
}
.cb-toggle-switch .cb-switch.cb-checked .cb-switch-track {
  background: #324dc7;
  border-color: #324dc7;
}
.cb-toggle-switch .cb-switch.cb-checked .cb-switch-wrap {
  transform: translateX(24px);
}
.cb-toggle-switch .cb-switch.cb-disabled .cb-switch-track {
  background: #d9d9d9;
  border-color: #d9d9d9;
}
.cb-toggle-switch .cb-switch.cb-disabled .cb-switch-wrap .cb-switch-thumb {
  background: #f0f0f0;
}
.cb-toggle-switch .cb-switch.cb-disabled + label {
  color: #989795;
}
.cb-toggle-switch .cb-switch:hover:not(.cb-disabled) .cb-switch-track {
  background: #505050;
  border-color: #505050;
}
.cb-toggle-switch .cb-switch:hover:not(.cb-disabled) .cb-switch-wrap .cb-switch-thumb {
  background: #f0f0f0;
}
.cb-toggle-switch .cb-switch.cb-checked:hover:not(.cb-disabled) .cb-switch-track {
  background: #28369a;
  border-color: #28369a;
}
.cb-toggle-switch .cb-switch.cb-checked:hover:not(.cb-disabled) .cb-switch-wrap .cb-switch-thumb {
  background: #f0f0f0;
}
.cb-toggle-switch .cb-switch input {
  left: 0;
  right: auto;
  position: absolute;
  top: 0;
  width: 68px;
  height: 48px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}
.cb-toggle-switch label + .cb-switch {
  margin-left: 8px;
}
.cb-toggle-switch .cb-switch + label {
  margin-left: 8px;
}

.cb-required::after {
  content: "*";
  font-size: 1rem;
  margin-left: 3px;
}

.cb-input-dropdown {
  border-radius: 8px;
}
.cb-input-dropdown .cb-dropdown-input {
  position: relative;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu {
  top: 0;
  left: 0;
  z-index: 899;
  display: none;
  min-width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  font-family: Roboto !important;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.7142857143em;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a {
  color: #1e1e1e;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a:hover, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-hover, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a:focus, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-focus {
  color: #1e1e1e;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a:active, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-active {
  color: #505050;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a:visited, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-visited {
  color: #1e1e1e;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled:hover, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled.cb-hover, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled:focus, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled.cb-focus, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled.cb-active, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled:visited, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu a.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul {
  list-style: none;
  padding: 0 0 12px 0px;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  font-size: 0.875rem;
  line-height: 1.1428571429em;
  white-space: nowrap;
  text-decoration: none !important;
  transition: all 0.25s ease-in-out;
}
@media (max-width: 1023.98px) {
  .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a {
    padding: 16px 24px;
    white-space: normal;
  }
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a:hover, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a:active {
  background-color: #f0f0f0;
  box-shadow: inset 2px 0px 0px 0px #1e1e1e;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a .cb-glyph,
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a .cb-icon {
  color: #b2b2b2;
  margin-left: 8px;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul.cb-dropdown-left-icon > li a {
  justify-content: flex-start;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul.cb-dropdown-left-icon > li a .cb-glyph,
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul.cb-dropdown-left-icon > li a .cb-icon {
  margin-left: 0;
  margin-right: 8px;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-dropdown-header {
  padding: 4px 24px;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-dropdown-header h3,
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-dropdown-header h4,
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-dropdown-header h5 {
  font-size: 0.75rem;
  line-height: 1.3333333333em;
  color: #888888;
  font-weight: 700;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-disabled a {
  color: #b2b2b2;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-disabled a:hover, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-disabled a:focus, .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-disabled a:active {
  color: #b2b2b2;
  cursor: not-allowed;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li.cb-divider {
  height: 1px;
  overflow: hidden;
  margin: 12px 0;
  background-color: #d9d9d9;
  padding: 0;
}
.cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu li input:focus {
  outline: 0;
}
.cb-input-dropdown.cb-input.cb-open input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: transparent;
  border-bottom: 0;
  box-shadow: none;
}
.cb-input-dropdown.cb-input.cb-open .cb-dropdown-menu {
  position: relative;
  display: block;
  border: 1px solid transparent;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.cb-input-dropdown.cb-input.cb-open .cb-input-icon-right .cb-glyph:not(.cb-x-mark),
.cb-input-dropdown.cb-input.cb-open .cb-input-icon-right .cb-icon:not(.cb-x-mark) {
  display: none;
}
.cb-input-dropdown.cb-open {
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
}
.cb-input-dropdown .cb-btn {
  top: 8px !important;
}
.cb-input-dropdown.cb-input-dropdown-overlay.cb-open .cb-dropdown-menu {
  position: absolute;
  top: -6px;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.2);
}
.cb-input-dropdown.cb-input-dropdown-scroll .cb-dropdown-input .cb-dropdown-menu {
  overflow-y: auto;
}

.cb-input-dropdown-overflow {
  position: relative;
  width: 100%;
  min-height: 48px;
}
.cb-input-dropdown-overflow .cb-input-dropdown {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

[data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch:focus .cb-switch-track, [data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch.cb-focus .cb-switch-track {
  background: #888888;
  border-color: #ffffff;
  box-shadow: 0 0 0 2px #324dc7;
}
[data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch:focus .cb-switch-wrap .cb-switch-thumb, [data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch.cb-focus .cb-switch-wrap .cb-switch-thumb {
  background: #ffffff;
  border-color: #ffffff;
}
[data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch.cb-checked:focus .cb-switch-track, [data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch.cb-checked.cb-focus .cb-switch-track {
  background: #324dc7;
  border-color: #ffffff;
  box-shadow: 0 0 0 2px #324dc7;
}
[data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch.cb-checked:focus .cb-switch-wrap .cb-switch-thumb, [data-cb-input-m=keyboard] .cb-toggle-switch .cb-switch.cb-checked.cb-focus .cb-switch-wrap .cb-switch-thumb {
  background: #f0f0f0;
  border-color: #ffffff;
}
[data-cb-input-m=keyboard] .cb-radio input[type=radio]:focus {
  outline: none;
}
[data-cb-input-m=keyboard] .cb-radio input[type=radio]:focus::before {
  box-shadow: 0 0 0 2px #324dc7;
}
[data-cb-input-m=keyboard] .cb-checkbox input[type=checkbox]:focus {
  outline: none;
}
[data-cb-input-m=keyboard] .cb-checkbox input[type=checkbox]:focus::before {
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 2px #324dc7;
}
[data-cb-input-m=keyboard] .cb-toggles-btn input[type=radio]:focus + span:not(.cb-glyph):not(.cb-icon) {
  box-shadow: inset 0 0 1px 2px #505050;
}
[data-cb-input-m=keyboard] .cb-btn-input:not(.cb-clear-input):not(.cb-password-input) .cb-btn:focus {
  box-shadow: none;
  outline: -webkit-focus-ring-color auto 5px;
}
[data-cb-input-m=keyboard] .cb-btn-input.cb-password-input .cb-btn:focus {
  box-shadow: none;
}
[data-cb-input-m=keyboard] .cb-btn-input.cb-password-input .cb-btn:focus .cb-glyph,
[data-cb-input-m=keyboard] .cb-btn-input.cb-password-input .cb-btn:focus .cb-icon {
  color: #1e1e1e;
  font-weight: 900;
}
[data-cb-input-m=keyboard] .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px #324dc7;
}
[data-cb-input-m=keyboard] .cb-input-dropdown .cb-dropdown-input .cb-dropdown-menu ul > li > a:focus[aria-disabled=true] {
  outline: 0;
  box-shadow: inset 0 0 0 2px #d9d9d9;
}

.cb-label-left {
  padding: 8px;
  background: transparent;
  margin-left: -8px;
}
.cb-label-left.cb-toggle-switch {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.cb-label-left.cb-toggle-switch label {
  flex-grow: 1;
  justify-content: start;
}
.cb-label-left.cb-radio label, .cb-label-left.cb-checkbox label {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.cb-label-left.cb-radio label.cb-required input, .cb-label-left.cb-checkbox label.cb-required input {
  margin-left: auto;
}
.cb-label-left.cb-radio .cb-label, .cb-label-left.cb-checkbox .cb-label {
  margin-left: 0;
}
.cb-label-left:hover {
  cursor: pointer;
  border-radius: 4px;
  background: #f0f0f0;
  transition: all 0.25s ease-in-out;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cb-btn-input .cb-btn {
    display: none !important;
  }
}
.cb-select {
  position: relative;
  width: 100%;
}
.cb-select label.cb-disabled {
  color: #888888;
}
.cb-select .cb-select-container {
  position: relative;
  width: 100%;
}
.cb-select .cb-select-container select.cb-replaced {
  position: relative;
  z-index: 10;
  width: 100%;
  opacity: 0;
  border: 0;
  height: 3rem;
}
.cb-select .cb-select-container select.cb-replaced:hover {
  cursor: pointer;
}
.cb-select .cb-select-container select.cb-replaced:disabled {
  cursor: not-allowed;
}
.cb-select .cb-select-container span.cb-select {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 3rem;
}
.cb-select .cb-select-container span.cb-select > span {
  display: block;
  line-height: 1.5em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  height: 3rem;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding: 11px 15px;
  border-radius: 8px;
}
.cb-select .cb-select-container span.cb-select span span {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  height: 24px;
}
.cb-select .cb-select-container span.cb-select .cb-select-arrow {
  position: absolute;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 0.6875rem;
  font-weight: 700;
  top: -9px;
  right: -15px;
}
.cb-select .cb-select-container span.cb-select .cb-select-arrow::before {
  position: absolute;
  top: 17px;
  left: 13px;
}
.cb-select .cb-select-container span.cb-select.focus > span {
  border: 1px solid #324dc7;
  box-shadow: inset 0px 0px 1px 0.5px rgb(48, 73, 207);
  padding: 11px 15px;
}
.cb-select .cb-select-container span.cb-select.disabled > span {
  cursor: default;
  color: #888888;
  opacity: 1;
}
.cb-select.cb-not-active option[disabled][hidden] {
  display: none;
  visibility: hidden;
}

.cb-input-condensed.cb-select .cb-select-container select.cb-replaced {
  height: 2.5rem;
}
.cb-input-condensed.cb-select .cb-select-container span.cb-select {
  height: 2.5rem;
}
.cb-input-condensed.cb-select .cb-select-container span.cb-select > span {
  height: 2.5rem;
  padding: 7px 15px;
}
.cb-input-condensed.cb-select .cb-select-container span.cb-select .cb-select-arrow {
  top: -9px;
  right: -15px;
}
.cb-input-condensed.cb-select .cb-select-container span.cb-select.focus > span {
  padding: 7px 15px;
}

.cb-select[multiple] {
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding: 11px 0;
  border-radius: 8px;
}
.cb-select[multiple] option {
  padding: 0 15px;
  height: 1.5em;
  display: flex;
  align-items: center;
}
.cb-select[multiple] option:hover {
  background-color: #f0f0f0;
  box-shadow: inset 2px 0px 0px 0px #1e1e1e;
}
.cb-select[multiple]:focus {
  border: 1px solid #324dc7;
  box-shadow: inset 0px 0px 1px 0.5px rgb(48, 73, 207);
}

.cb-floating-label {
  position: relative;
}
.cb-floating-label label {
  z-index: 2;
  position: absolute;
  left: 16px;
  top: 12px;
  line-height: 1.5em;
  background-color: #ffffff;
  transition-property: top, font-size;
  transition-duration: 0.1s;
  transition-timing-function: linear;
}
.cb-floating-label label.cb-required::after {
  background-color: #ffffff;
  padding: 0 2px 0 4px;
  margin-left: 2px;
}
.cb-floating-label label.cb-value-fl, .cb-floating-label label.cb-focus-fl, .cb-floating-label label.cb-fill-fl {
  color: #505050;
  font-size: 0.75rem;
  line-height: 1.7142857143em;
  font-weight: 500;
  background-color: #ffffff;
  padding: 0 2px;
  left: 14px;
  top: -10px;
}
.cb-floating-label label.cb-focus-fl {
  color: #324dc7;
  font-weight: 500;
}
.cb-floating-label .cb-input-icon-left label {
  left: 39px;
}
.cb-floating-label .cb-input-icon-left label.cb-value-fl, .cb-floating-label .cb-input-icon-left label.cb-focus-fl {
  left: 14px;
}
.cb-floating-label .cb-input-icon-left .cb-glyph,
.cb-floating-label .cb-input-icon-left .cb-icon {
  top: 12px;
}
.cb-floating-label .cb-input-icon-right .cb-glyph,
.cb-floating-label .cb-input-icon-right .cb-icon {
  top: 12px;
}
.cb-floating-label.cb-input-condensed label {
  top: 9px;
}
.cb-floating-label.cb-input-condensed label.cb-value-fl, .cb-floating-label.cb-input-condensed label.cb-focus-fl {
  left: 14px;
  top: -10px;
}
.cb-floating-label.cb-input-condensed .cb-input-icon-left .cb-glyph,
.cb-floating-label.cb-input-condensed .cb-input-icon-left .cb-icon {
  top: 9px;
  left: 16px;
}
.cb-floating-label.cb-input-condensed .cb-input-icon-right .cb-glyph,
.cb-floating-label.cb-input-condensed .cb-input-icon-right .cb-icon {
  top: 9px;
}
.cb-floating-label.cb-btn-input .cb-btn {
  top: 8px;
}
.cb-floating-label input:-webkit-autofill,
.cb-floating-label input:-webkit-autofill:hover,
.cb-floating-label input:-webkit-autofill:focus,
.cb-floating-label input:-webkit-autofill:active {
  animation-name: onAutoFillStart;
  transition: font-size 3000s ease-in-out 0s;
}

.cb-floating-label.cb-not-active label {
  position: relative;
  top: 0;
  left: 0;
}

@keyframes onAutoFillStart {
  from {
    font-size: inherit;
  }
  to {
    font-size: inherit;
  }
}
.cb-floating-label label.cb-dark-bg {
  background: transparent !important;
}
.cb-floating-label label.cb-dark-bg.cb-value-fl, .cb-floating-label label.cb-dark-bg.cb-focus-fl, .cb-floating-label label.cb-dark-bg.cb-fill-fl {
  color: #ffffff;
  top: -20px;
}
.cb-floating-label label.cb-light-bg {
  background: transparent !important;
}
.cb-floating-label label.cb-light-bg.cb-value-fl, .cb-floating-label label.cb-light-bg.cb-focus-fl, .cb-floating-label label.cb-light-bg.cb-fill-fl {
  color: #1e1e1e;
  top: -20px;
}
.cb-floating-label label.cb-required::after {
  background-color: transparent;
}

[class*=safari] .cb-floating-label label {
  top: 13px;
}
[class*=safari] .cb-floating-label label.cb-value-fl, [class*=safari] .cb-floating-label label.cb-focus-fl, [class*=safari] .cb-floating-label label.cb-fill-fl {
  top: -10px;
}
[class*=safari] .cb-floating-label.cb-select label {
  top: 14px;
}
[class*=safari] .cb-floating-label.cb-select label.cb-value-fl, [class*=safari] .cb-floating-label.cb-select label.cb-focus-fl {
  top: -10px;
}
[class*=safari] .cb-floating-label.cb-input-condensed label {
  top: 10px;
}
[class*=safari] .cb-floating-label.cb-input-condensed label.cb-value-fl, [class*=safari] .cb-floating-label.cb-input-condensed label.cb-focus-fl, [class*=safari] .cb-floating-label.cb-input-condensed label.cb-fill-fl {
  top: -10px;
}

.cb-validation-state,
.cb-validation-success,
.cb-validation-error {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
}
.cb-validation-state.cb-textarea,
.cb-validation-success.cb-textarea,
.cb-validation-error.cb-textarea {
  align-items: flex-start;
}
.cb-validation-state .cb-input-icon-right,
.cb-validation-state .cb-input-icon-left,
.cb-validation-state .cb-validation-label-input,
.cb-validation-success .cb-input-icon-right,
.cb-validation-success .cb-input-icon-left,
.cb-validation-success .cb-validation-label-input,
.cb-validation-error .cb-input-icon-right,
.cb-validation-error .cb-input-icon-left,
.cb-validation-error .cb-validation-label-input {
  position: relative;
  flex-grow: 1;
}
.cb-validation-state .cb-validation-icon,
.cb-validation-success .cb-validation-icon,
.cb-validation-error .cb-validation-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3rem;
  line-height: 1em;
}
.cb-validation-state.cb-input-condensed .cb-validation-icon,
.cb-validation-success.cb-input-condensed .cb-validation-icon,
.cb-validation-error.cb-input-condensed .cb-validation-icon {
  width: 2.5em;
  height: 2.5rem;
}

fieldset.cb-validation-state, fieldset.cb-validation-success, fieldset.cb-validation-error {
  flex-direction: column;
  align-items: flex-start;
}

.cb-validation-success .cb-validation-icon.cb-glyph, .cb-validation-success .cb-validation-icon.cb-icon {
  color: #1d7846;
}

.cb-validation-success .cb-input-helper, .cb-validation-success.cb-input-helper {
  align-items: start;
}
.cb-validation-success .cb-input-helper::before, .cb-validation-success.cb-input-helper::before {
  font-size: 1rem;
  line-height: 1em;
  margin-right: 8px;
  font-family: CB Icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e912";
  color: #d9d9d9;
}

.cb-validation-error.cb-input input,
.cb-validation-error.cb-input textarea {
  border-color: #ab2334;
}
.cb-validation-error.cb-input input:focus,
.cb-validation-error.cb-input textarea:focus {
  border-color: #ab2334;
  box-shadow: inset 0px 0px 1px 0.5px #ab2334;
}
.cb-validation-error.cb-input .cb-validation-icon.cb-glyph, .cb-validation-error.cb-input .cb-validation-icon.cb-icon {
  color: #ab2334;
}
.cb-validation-error.cb-radio input[type=radio]::before,
.cb-validation-error .cb-radio input[type=radio]::before {
  border-color: #ab2334;
}
.cb-validation-error.cb-checkbox input[type=checkbox]::before,
.cb-validation-error .cb-checkbox input[type=checkbox]::before {
  border-color: #ab2334;
}
.cb-validation-error.cb-select .cb-select-container span.cb-select > span,
.cb-validation-error .cb-select .cb-select-container span.cb-select > span {
  border-color: #ab2334;
}
.cb-validation-error.cb-select .cb-select-container span.cb-select.focus > span,
.cb-validation-error .cb-select .cb-select-container span.cb-select.focus > span {
  border-color: #ab2334;
  box-shadow: inset 0px 0px 1px 0.5px #ab2334;
}
.cb-validation-error label.cb-value-fl, .cb-validation-error label.cb-focus-fl {
  color: #ab2334;
}
.cb-validation-error .cb-input-helper, .cb-validation-error.cb-input-helper {
  align-items: start;
}
.cb-validation-error .cb-input-helper::before, .cb-validation-error.cb-input-helper::before {
  font-size: 1rem;
  line-height: 1em;
  margin-right: 8px;
  font-family: CB Icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e90f";
  color: #ab2334;
}

.cb-validation-error .cb-validation-icon.cb-glyph, .cb-validation-error .cb-validation-icon.cb-icon {
  color: #ab2334;
}

.cb-input-helper-block:not(.cb-notification) {
  transition: height 0.35s ease;
  width: calc(100% - 48px);
}
.cb-input-helper-block:not(.cb-notification) ul {
  margin: 0;
  padding: 0;
  list-style: none;
  opacity: 0;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper p,
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper span {
  color: #505050;
  font-size: 0.75rem;
  line-height: 1.3333333333em;
}
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper::before {
  transition: all 0.35s ease-in-out;
}
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper:not(.cb-validation-error):not(.cb-validation-success)::before {
  font-size: 1rem;
  line-height: 1em;
  margin-right: 8px;
  font-family: CB Icons;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e912";
  color: #d9d9d9;
}
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper.cb-validation-success::before {
  content: "\e95d";
  animation-name: validIcon;
  animation-duration: 0.25s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}
.cb-input-helper-block:not(.cb-notification) ul li.cb-input-helper.cb-validation-error::before {
  content: "\e913";
  color: #d9d9d9;
}
.cb-input-helper-block:not(.cb-notification).cb-show-err-state ul li.cb-input-helper.cb-validation-error::before {
  content: "\e90f";
  color: #ab2334;
}
.cb-input-helper-block:not(.cb-notification).cb-hidden {
  height: 0;
  overflow-y: hidden;
}
.cb-input-helper-block:not(.cb-notification).cb-hidden ul {
  animation-name: fadeOut;
}
.cb-input-helper-block:not(.cb-notification).cb-show {
  overflow-y: auto;
  height: auto;
}
.cb-input-helper-block:not(.cb-notification).cb-show ul {
  animation-name: fadeIn;
}
.cb-input-helper-block.cb-notification ul li a.cb-input-helper {
  font-size: 1rem;
  line-height: 1.5em;
  display: block;
}
.cb-input-helper-block.cb-notification ul li a.cb-input-helper::before {
  margin-right: 0;
  content: "";
}
.cb-input-helper-block.cb-notification ul li a.cb-input-helper.cb-validation-success::before {
  margin-right: 0;
  content: "";
}
.cb-input-helper-block.cb-notification ul li a.cb-input-helper.cb-validation-error::before {
  margin-right: 0;
  content: "";
}

@keyframes validIcon {
  from {
    content: "\e900";
    color: #1d7846;
  }
  to {
    content: "\e912";
    color: #d9d9d9;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.cb-clear-input {
  position: relative;
}
.cb-clear-input .cb-btn {
  display: none;
}
.cb-clear-input.cb-validation-success-ci .cb-glyph.cb-check,
.cb-clear-input.cb-validation-success-ci .cb-icon.cb-check {
  color: transparent;
}
.cb-clear-input.cb-value-ci:not(.cb-focus-elem-ci):not(.cb-focus-btn-ci).cb-validation-success-ci .cb-glyph.cb-check,
.cb-clear-input.cb-value-ci:not(.cb-focus-elem-ci):not(.cb-focus-btn-ci).cb-validation-success-ci .cb-icon.cb-check {
  color: #1d7846;
}
.cb-clear-input.cb-focus-elem-ci.cb-value-ci .cb-input-icon-right .cb-glyph,
.cb-clear-input.cb-focus-elem-ci.cb-value-ci .cb-input-icon-right .cb-icon, .cb-clear-input.cb-focus-btn-ci.cb-value-ci .cb-input-icon-right .cb-glyph,
.cb-clear-input.cb-focus-btn-ci.cb-value-ci .cb-input-icon-right .cb-icon {
  display: none;
}
.cb-clear-input.cb-focus-elem-ci.cb-value-ci .cb-input-icon-right .cb-btn .cb-glyph,
.cb-clear-input.cb-focus-elem-ci.cb-value-ci .cb-input-icon-right .cb-btn .cb-icon, .cb-clear-input.cb-focus-btn-ci.cb-value-ci .cb-input-icon-right .cb-btn .cb-glyph,
.cb-clear-input.cb-focus-btn-ci.cb-value-ci .cb-input-icon-right .cb-btn .cb-icon {
  display: block;
}
.cb-clear-input.cb-focus-elem-ci.cb-value-ci .cb-btn, .cb-clear-input.cb-focus-btn-ci.cb-value-ci .cb-btn {
  display: block;
}

.cb-file-upload input[type=file] {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
.cb-file-upload .cb-file-element {
  white-space: nowrap;
  line-height: 24px;
  display: block;
  margin-top: 24px;
}
.cb-file-upload .cb-file-element-rm {
  margin-top: 24px;
  display: block;
  max-width: fit-content;
  white-space: nowrap;
  text-decoration: none;
  color: #1e1e1e;
}
.cb-file-upload .cb-file-element-rm:hover, .cb-file-upload .cb-file-element-rm.cb-hover, .cb-file-upload .cb-file-element-rm:focus, .cb-file-upload .cb-file-element-rm.cb-focus {
  color: #1e1e1e;
}
.cb-file-upload .cb-file-element-rm:active, .cb-file-upload .cb-file-element-rm.cb-active {
  color: #505050;
}
.cb-file-upload .cb-file-element-rm:visited, .cb-file-upload .cb-file-element-rm.cb-visited {
  color: #1e1e1e;
}
.cb-file-upload .cb-file-element-rm.cb-disabled {
  color: #b2b2b2 !important;
  text-decoration: none !important;
  cursor: not-allowed;
}
.cb-file-upload .cb-file-element-rm.cb-disabled:hover, .cb-file-upload .cb-file-element-rm.cb-disabled.cb-hover, .cb-file-upload .cb-file-element-rm.cb-disabled:focus, .cb-file-upload .cb-file-element-rm.cb-disabled.cb-focus, .cb-file-upload .cb-file-element-rm.cb-disabled.cb-active, .cb-file-upload .cb-file-element-rm.cb-disabled:visited, .cb-file-upload .cb-file-element-rm.cb-disabled.cb-visited {
  color: #ffffff;
  color: #b2b2b2 !important;
  text-decoration: none !important;
}
.cb-file-upload .cb-file-element-rm.cb-glyph {
  cursor: pointer;
}
.cb-file-upload .cb-file-element-rm.cb-glyph::before {
  content: "";
}
.cb-file-upload .cb-file-element-rm.cb-glyph::after {
  font-size: 0.75rem;
  content: "\e955";
  padding-left: 16px;
}
.cb-file-upload .cb-file-element-rm.cb-icon {
  cursor: pointer;
}
.cb-file-upload .cb-file-element-rm.cb-icon::before {
  content: "";
}
.cb-file-upload .cb-file-element-rm.cb-icon::after {
  font-size: 0.75rem;
  content: "\e91a";
  padding-left: 16px;
}
.cb-file-upload .cb-file-element-rm .cb-file-element {
  margin-top: 0;
  font-family: Roboto !important;
  font-weight: 400;
  display: inline-block;
  text-decoration: none;
}
.cb-file-upload .cb-file-element-rm.cb-glyph:hover .cb-file-element, .cb-file-upload .cb-file-element-rm.cb-icon:hover .cb-file-element {
  text-decoration: underline;
}
.cb-file-upload .cb-file-name {
  word-break: break-word;
}