.cb-accordion .cb-accordion-controls {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.cb-accordion .cb-accordion-controls .cb-accordion-buttons {
  margin-left: auto;
  padding-left: 24px;
}
.cb-accordion .cb-accordion-controls .cb-accordion-buttons .cb-btn {
  padding: 0;
  border: 0;
  border-radius: 0;
}
.cb-accordion .cb-accordion-controls .cb-accordion-buttons .cb-btn:first-of-type {
  padding-right: 24px;
  border-right: 1px solid #d9d9d9;
  margin-right: 24px;
}
.cb-accordion .cb-accordion-controls .cb-accordion-buttons .cb-btn:hover, .cb-accordion .cb-accordion-controls .cb-accordion-buttons .cb-btn:focus {
  background-color: transparent;
  box-shadow: none;
}
.cb-accordion .cb-accordion-controls .cb-accordion-title h2,
.cb-accordion .cb-accordion-controls .cb-accordion-title h3,
.cb-accordion .cb-accordion-controls .cb-accordion-title h4 {
  font-family: Roboto !important;
  font-weight: 400;
}
@media (min-width: 768px) {
  .cb-accordion .cb-accordion-controls .cb-accordion-title h2,
.cb-accordion .cb-accordion-controls .cb-accordion-title h3,
.cb-accordion .cb-accordion-controls .cb-accordion-title h4 {
    font-size: 1.75rem;
    line-height: 1.1428571429em;
  }
}
@media (min-width: 1248px) {
  .cb-accordion .cb-accordion-controls .cb-accordion-title h2,
.cb-accordion .cb-accordion-controls .cb-accordion-title h3,
.cb-accordion .cb-accordion-controls .cb-accordion-title h4 {
    font-size: 2rem;
    line-height: 1.25em;
  }
}
.cb-accordion .cb-accordion-panels {
  border-top: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container {
  display: flex;
  flex-direction: column;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading {
  padding: 24px;
  text-decoration: none;
  color: #1e1e1e;
  border-top: 1px solid #d9d9d9;
  transition: all 0.25s ease-in-out;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading .cb-accordion-heading-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading .cb-accordion-heading-title span {
  font-family: Roboto !important;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5em;
  margin-right: 16px;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading .cb-accordion-heading-content {
  margin-right: 32px;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-active .cb-accordion-icon .line.top {
  transform: rotate(360deg);
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-active .cb-accordion-icon .line.bottom {
  transform: rotate(360deg);
}
@media (prefers-reduced-motion: reduce) {
  .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-active .cb-accordion-icon .line.top {
    display: none;
  }
  .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-active .cb-accordion-icon .line.bottom {
    transform: rotate(180deg);
  }
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading:hover {
  cursor: pointer;
  color: #1e1e1e;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading:hover .cb-accordion-heading-title {
  text-decoration: underline;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-heading:not(.cb-active):hover {
  background-color: #f0f0f0;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container:first-of-type .cb-accordion-heading {
  border-top: 0;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel {
  height: 0;
  overflow: hidden;
  padding: 0;
  visibility: hidden;
  background: #ffffff;
  background: linear-gradient(180deg, #f0f0f0 0%, white 20%);
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel .cb-accordion-panel-content {
  padding: 24px;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel.transition {
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel.transition {
    transition: none;
  }
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel.cb-in {
  height: auto;
  overflow-y: auto;
  visibility: visible;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel .cb-accordion .cb-accordion-panels {
  border-top: 0;
}
.cb-accordion .cb-accordion-panels .cb-accordion-container .cb-accordion-panel .cb-accordion .cb-accordion-panels .cb-accordion-heading {
  background-color: #f0f0f0;
  border-top: 1px solid #b2b2b2;
}

.cb-accordion-trigger {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.cb-accordion-trigger .cb-accordion-icon {
  width: 14px;
  height: 14px;
}
.cb-accordion-trigger .cb-accordion-icon .line {
  top: 6px;
}
.cb-accordion-trigger.cb-active .line.top {
  transform: rotate(360deg);
}
.cb-accordion-trigger.cb-active .line.bottom {
  transform: rotate(360deg);
}
@media (prefers-reduced-motion: reduce) {
  .cb-accordion-trigger.cb-active .line.top {
    display: none;
  }
  .cb-accordion-trigger.cb-active .line.bottom {
    transform: rotate(180deg);
  }
}
.cb-accordion-trigger:hover .cb-accordion-icon .line {
  background: #324dc7;
}
.cb-accordion-trigger:active .cb-accordion-icon .line {
  background: #28369a;
}
.cb-accordion-trigger.cb-link-blue {
  text-decoration: none;
}
.cb-accordion-trigger.cb-link-blue:hover, .cb-accordion-trigger.cb-link-blue.cb-hover {
  text-decoration: underline;
}
.cb-accordion-trigger.cb-link-blue:not(.cb-btn):visited, .cb-accordion-trigger.cb-link-blue:not(.cb-btn).cb-visited {
  color: #324dc7;
}
.cb-accordion-trigger.cb-link-blue .cb-accordion-icon .line {
  background: #324dc7;
}
.cb-accordion-trigger.cb-link-blue:hover .cb-accordion-icon .line {
  background: #324dc7;
}
.cb-accordion-trigger.cb-link-blue:active .cb-accordion-icon .line, .cb-accordion-trigger.cb-link-blue.cb-active .cb-accordion-icon .line {
  background: #324dc7;
}

.cb-accordion-trigger-panel {
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: margin 0.35s ease;
  visibility: hidden;
}
.cb-accordion-trigger-panel.transition {
  transition: height 0.35s ease;
}
.cb-accordion-trigger-panel.cb-in {
  height: auto;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 24px;
  visibility: visible;
}
.cb-accordion-trigger-panel .cb-accordion-panel-content {
  padding: 24px 0;
}

.cb-accordion-icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.cb-accordion-icon .line {
  position: absolute;
  height: 2px;
  width: 100%;
  background: #1e1e1e;
  top: 7px;
  transition: all 0.25s;
}
.cb-accordion-icon .line.top {
  transform: rotate(90deg);
}
.cb-accordion-icon .line.bottom {
  transform: rotate(180deg);
}
.cb-accordion-icon.cb-active .line.top {
  transform: rotate(360deg);
}
.cb-accordion-icon.cb-active .line.bottom {
  transform: rotate(360deg);
}
@media (prefers-reduced-motion: reduce) {
  .cb-accordion-icon.cb-active .line.top {
    display: none;
  }
  .cb-accordion-icon.cb-active .line.bottom {
    transform: rotate(180deg);
  }
}

.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading .cb-accordion-heading-title {
  align-items: flex-start;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading .cb-accordion-heading-title .cb-accordion-icon {
  margin-top: 4px;
  margin-left: 16px;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-accordion-left-icon .cb-accordion-heading-title {
  justify-content: unset;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-accordion-left-icon .cb-accordion-heading-title .cb-accordion-icon {
  margin-right: 24px;
  margin-left: 0;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled {
  background: #f0f0f0;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled .cb-accordion-icon {
  font-family: CB Icons;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  font-size: 1.2rem;
  line-height: 1em;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled .cb-accordion-icon span {
  display: none;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled .cb-accordion-icon::before, .cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled .cb-accordion-icon::after {
  position: absolute;
  top: -50%;
  left: -50%;
  font-size: 2em;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled .cb-accordion-icon::before {
  content: "\e925";
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled:hover {
  cursor: not-allowed !important;
}
.cb-accordion.cb-accordion-simple .cb-accordion-panels .cb-accordion-container .cb-accordion-heading.cb-disabled:hover .cb-accordion-heading-title {
  text-decoration: none !important;
}