.cb-modal {
  display: none;
}
.cb-modal.cb-open {
  display: block;
}
.cb-modal[aria-hidden=false] .cb-modal-overlay {
  animation: cbFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.cb-modal[aria-hidden=false] .cb-modal-container {
  animation: cbSlideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}
@media (prefers-reduced-motion: reduce) {
  .cb-modal[aria-hidden=false] .cb-modal-overlay {
    animation: cbFadeIn-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .cb-modal[aria-hidden=false] .cb-modal-container {
    animation: cbSlideIn-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}
.cb-modal[aria-hidden=true] .cb-modal-overlay {
  animation: cbFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
.cb-modal[aria-hidden=true] .cb-modal-container {
  animation: cbSlideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}
@media (prefers-reduced-motion: reduce) {
  .cb-modal[aria-hidden=true] .cb-modal-overlay {
    animation: cbFadeOut-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
  .cb-modal[aria-hidden=true] .cb-modal-container {
    animation: cbSlideOut-motion 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}
.cb-modal .cb-modal-container {
  background-color: #ffffff;
  padding: 24px;
  width: 900px;
  max-height: calc(100vh - 48px);
  max-width: calc(100vw - 48px);
  box-sizing: border-box;
  border-radius: 8px;
}
@media (max-width: 1247.98px) {
  .cb-modal .cb-modal-container {
    width: 600px;
  }
}
@media (max-width: 767.98px) {
  .cb-modal .cb-modal-container {
    width: auto;
  }
}
.cb-modal .cb-modal-container:not(.cb-modal-overflow) {
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .cb-modal .cb-modal-container {
    margin: 12px;
  }
}
.cb-modal .cb-modal-container .cb-modal-header {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 30px;
  margin-bottom: 24px;
}
.cb-modal .cb-modal-container .cb-modal-header .cb-modal-title {
  font-size: 1.3125rem;
  line-height: 1.1428571429em;
  font-weight: 500;
}
@media (min-width: 768px) {
  .cb-modal .cb-modal-container .cb-modal-header .cb-modal-title {
    font-size: 1.75rem;
    line-height: 1.1428571429em;
    font-weight: 400;
  }
}
@media (min-width: 1248px) {
  .cb-modal .cb-modal-container .cb-modal-header .cb-modal-title {
    font-size: 2rem;
    line-height: 1.25em;
    font-weight: 400;
  }
}
.cb-modal .cb-modal-container .cb-modal-header.cb-modal-has-close .cb-modal-title {
  max-width: calc(100% - 40px);
}
.cb-modal .cb-modal-container .cb-modal-header .cb-btn-close {
  position: absolute;
  top: 0;
  right: 0;
}
.cb-modal .cb-modal-container .cb-modal-content {
  margin-bottom: 24px;
}
.cb-modal .cb-modal-container.cb-modal-overflow .cb-modal-content {
  overflow-y: unset !important;
}
.cb-modal .cb-modal-container .cb-modal-footer {
  display: flex;
  justify-content: flex-end;
}
.cb-modal.cb-dialog-modal .cb-modal-container {
  padding: 0;
}
.cb-modal.cb-dialog-modal .cb-modal-container .cb-modal-content {
  margin-bottom: 0;
  overflow: hidden !important;
  height: auto !important;
}
.cb-modal.cb-dialog-modal .cb-modal-container .cb-notification {
  padding: 0;
  margin-bottom: 0;
  border: 0;
}
.cb-modal.cb-dialog-modal .cb-modal-container .cb-notification .cb-notification-container .cb-btn.cb-btn-close {
  margin-right: 0;
  margin-top: 0;
}
.cb-modal.cb-video-modal .cb-modal-container {
  background-color: grey;
  padding: 0;
}
.cb-modal.cb-video-modal .cb-modal-container .cb-modal-header {
  margin-bottom: 0;
  position: fixed;
  top: 24px;
  right: 24px;
}
.cb-modal.cb-video-modal .cb-modal-container .cb-modal-content {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
}
.cb-modal.cb-video-modal .cb-modal-container .cb-modal-content iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.cb-modal.cb-spinner-modal .cb-modal-container {
  padding: 0 !important;
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  max-width: 100px;
  min-height: 100px;
  outline: 0 !important;
}
.cb-modal.cb-spinner-modal .cb-modal-container .cb-modal-content {
  margin: 0 !important;
  height: 100% !important;
  overflow: unset !important;
}
.cb-modal.cb-photo-gallery-modal .cb-modal-container {
  padding: 0;
}

.cb-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(30, 30, 30, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040;
}
.cb-modal-overlay.cb-modal-over-tooltip {
  z-index: 1075;
}

.cb-modal-open {
  overflow: hidden;
}

.cb-photo-gallery-modal .cb-modal-overlay {
  background-color: #1e1e1e;
}
.cb-photo-gallery-modal .cb-modal-overlay .cb-modal-container {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  border-radius: 0;
  margin: 0;
}
.cb-photo-gallery-modal .cb-modal-overlay .cb-modal-container .cb-modal-content {
  margin-bottom: 0;
}

[data-cb-input-m=keyboard] .cb-modal.cb-video-modal .cb-modal-container .cb-modal-footer .cb-btn-row {
  position: relative;
}
[data-cb-input-m=keyboard] .cb-modal.cb-video-modal .cb-modal-container .cb-modal-footer .cb-btn-row .cb-btn[data-cb-modal-close] {
  position: absolute;
  right: -200px;
}
[data-cb-input-m=keyboard] .cb-modal.cb-video-modal .cb-modal-container .cb-modal-footer .cb-btn-row .cb-btn[data-cb-modal-close]:focus {
  right: 4px;
}
[data-cb-input-m=keyboard] .cb-modal.cb-video-modal .cb-modal-container .cb-modal-footer .cb-btn-row .cb-btn[data-cb-modal-close]:focus::after {
  box-shadow: inset 0 0 0 2px #ffffff;
}

@keyframes cbFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes cbFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes cbSlideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes cbSlideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
@keyframes cbFadeIn-motion {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
@keyframes cbFadeOut-motion {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes cbSlideIn-motion {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes cbSlideOut-motion {
  from {
    transform: translateY(-10%);
  }
  to {
    transform: translateY(-10%);
  }
}