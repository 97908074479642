.cb-stepper ul,
.cb-stepper ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.cb-stepper ul li,
.cb-stepper ol li {
  margin: 0;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0 8px;
}
.cb-stepper ul li p,
.cb-stepper ol li p {
  font-family: Roboto !important;
  font-weight: 700;
  margin: 0;
}
.cb-stepper ul li p + p,
.cb-stepper ol li p + p {
  font-size: 0.875rem;
  line-height: 1.7142857143em;
  font-family: Roboto !important;
  font-weight: 400;
}
.cb-stepper ul li a,
.cb-stepper ol li a {
  color: #1e1e1e;
  text-decoration: none;
}
.cb-stepper ul li a:hover, .cb-stepper ul li a:focus, .cb-stepper ul li a:active, .cb-stepper ul li a:visited,
.cb-stepper ol li a:hover,
.cb-stepper ol li a:focus,
.cb-stepper ol li a:active,
.cb-stepper ol li a:visited {
  color: #1e1e1e;
}
.cb-stepper ul li .cb-glyph,
.cb-stepper ul li .cb-icon,
.cb-stepper ol li .cb-glyph,
.cb-stepper ol li .cb-icon {
  display: inline-flex;
  color: #ffffff;
}
.cb-stepper ul li .cb-glyph-circular::after,
.cb-stepper ul li .cb-icon-circular::after,
.cb-stepper ol li .cb-glyph-circular::after,
.cb-stepper ol li .cb-icon-circular::after {
  background-color: transparent;
  border: 2px solid #888888;
}
.cb-stepper ul li .cb-glyph-circular::before,
.cb-stepper ul li .cb-icon-circular::before,
.cb-stepper ol li .cb-glyph-circular::before,
.cb-stepper ol li .cb-icon-circular::before {
  color: #888888;
}
.cb-stepper ul li .cb-glyph-numeric,
.cb-stepper ul li .cb-icon-numeric,
.cb-stepper ol li .cb-glyph-numeric,
.cb-stepper ol li .cb-icon-numeric {
  background-color: transparent;
  color: #888888;
  border-color: #888888;
  margin-bottom: 8px;
}
.cb-stepper ul li::after,
.cb-stepper ol li::after {
  height: 2px;
  width: calc(100% - 44px);
  background: #888888;
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: calc(50% + 22px);
}
.cb-stepper ul li.cb-stepper-active .cb-glyph,
.cb-stepper ul li.cb-stepper-active .cb-icon,
.cb-stepper ul li.cb-stepper-active .cb-glyph-numeric,
.cb-stepper ul li.cb-stepper-active .cb-icon-numeric,
.cb-stepper ol li.cb-stepper-active .cb-glyph,
.cb-stepper ol li.cb-stepper-active .cb-icon,
.cb-stepper ol li.cb-stepper-active .cb-glyph-numeric,
.cb-stepper ol li.cb-stepper-active .cb-icon-numeric {
  color: #ffffff;
}
.cb-stepper ul li.cb-stepper-active .cb-glyph-circular::after,
.cb-stepper ul li.cb-stepper-active .cb-glyph-circular::after,
.cb-stepper ol li.cb-stepper-active .cb-glyph-circular::after,
.cb-stepper ol li.cb-stepper-active .cb-glyph-circular::after {
  background-color: #324dc7;
  border: 2px solid #324dc7;
}
.cb-stepper ul li.cb-stepper-active .cb-glyph-circular::before,
.cb-stepper ul li.cb-stepper-active .cb-glyph-circular::before,
.cb-stepper ol li.cb-stepper-active .cb-glyph-circular::before,
.cb-stepper ol li.cb-stepper-active .cb-glyph-circular::before {
  color: #ffffff;
}
.cb-stepper ul li.cb-stepper-active .cb-glyph-numeric,
.cb-stepper ul li.cb-stepper-active .cb-icon-numeric,
.cb-stepper ol li.cb-stepper-active .cb-glyph-numeric,
.cb-stepper ol li.cb-stepper-active .cb-icon-numeric {
  background-color: #324dc7;
  border-color: #324dc7;
}
.cb-stepper ul li.cb-stepper-active::after,
.cb-stepper ol li.cb-stepper-active::after {
  background: #888888;
}
.cb-stepper ul li.cb-stepper-skip .cb-glyph,
.cb-stepper ul li.cb-stepper-skip .cb-icon,
.cb-stepper ol li.cb-stepper-skip .cb-glyph,
.cb-stepper ol li.cb-stepper-skip .cb-icon {
  color: #ffffff;
}
.cb-stepper ul li.cb-stepper-skip .cb-glyph.cb-glyph-circular::after, .cb-stepper ul li.cb-stepper-skip .cb-glyph.cb-icon-circular::after,
.cb-stepper ul li.cb-stepper-skip .cb-icon.cb-glyph-circular::after,
.cb-stepper ul li.cb-stepper-skip .cb-icon.cb-icon-circular::after,
.cb-stepper ol li.cb-stepper-skip .cb-glyph.cb-glyph-circular::after,
.cb-stepper ol li.cb-stepper-skip .cb-glyph.cb-icon-circular::after,
.cb-stepper ol li.cb-stepper-skip .cb-icon.cb-glyph-circular::after,
.cb-stepper ol li.cb-stepper-skip .cb-icon.cb-icon-circular::after {
  background-color: #888888;
  border: 2px solid #888888;
}
.cb-stepper ul li.cb-stepper-skip .cb-glyph.cb-glyph-circular::before, .cb-stepper ul li.cb-stepper-skip .cb-glyph.cb-icon-circular::before,
.cb-stepper ul li.cb-stepper-skip .cb-icon.cb-glyph-circular::before,
.cb-stepper ul li.cb-stepper-skip .cb-icon.cb-icon-circular::before,
.cb-stepper ol li.cb-stepper-skip .cb-glyph.cb-glyph-circular::before,
.cb-stepper ol li.cb-stepper-skip .cb-glyph.cb-icon-circular::before,
.cb-stepper ol li.cb-stepper-skip .cb-icon.cb-glyph-circular::before,
.cb-stepper ol li.cb-stepper-skip .cb-icon.cb-icon-circular::before {
  color: #ffffff;
}
.cb-stepper ul li.cb-stepper-skip .cb-glyph-numeric,
.cb-stepper ul li.cb-stepper-skip .cb-icon-numeric,
.cb-stepper ol li.cb-stepper-skip .cb-glyph-numeric,
.cb-stepper ol li.cb-stepper-skip .cb-icon-numeric {
  background-color: #888888;
  color: #ffffff;
  border-color: #888888;
}
.cb-stepper ul li.cb-stepper-skip::after,
.cb-stepper ol li.cb-stepper-skip::after {
  background: #324dc7;
}
.cb-stepper ul li.cb-stepper-complete .cb-glyph.cb-glyph-circular::after, .cb-stepper ul li.cb-stepper-complete .cb-glyph.cb-icon-circular::after,
.cb-stepper ul li.cb-stepper-complete .cb-icon.cb-glyph-circular::after,
.cb-stepper ul li.cb-stepper-complete .cb-icon.cb-icon-circular::after,
.cb-stepper ol li.cb-stepper-complete .cb-glyph.cb-glyph-circular::after,
.cb-stepper ol li.cb-stepper-complete .cb-glyph.cb-icon-circular::after,
.cb-stepper ol li.cb-stepper-complete .cb-icon.cb-glyph-circular::after,
.cb-stepper ol li.cb-stepper-complete .cb-icon.cb-icon-circular::after {
  background-color: #324dc7;
  border: 2px solid #324dc7;
}
.cb-stepper ul li.cb-stepper-complete .cb-glyph.cb-glyph-circular::before, .cb-stepper ul li.cb-stepper-complete .cb-glyph.cb-icon-circular::before,
.cb-stepper ul li.cb-stepper-complete .cb-icon.cb-glyph-circular::before,
.cb-stepper ul li.cb-stepper-complete .cb-icon.cb-icon-circular::before,
.cb-stepper ol li.cb-stepper-complete .cb-glyph.cb-glyph-circular::before,
.cb-stepper ol li.cb-stepper-complete .cb-glyph.cb-icon-circular::before,
.cb-stepper ol li.cb-stepper-complete .cb-icon.cb-glyph-circular::before,
.cb-stepper ol li.cb-stepper-complete .cb-icon.cb-icon-circular::before {
  color: #ffffff;
}
.cb-stepper ul li.cb-stepper-complete::after,
.cb-stepper ol li.cb-stepper-complete::after {
  background: #324dc7;
}
.cb-stepper ul li.cb-stepper-disabled a:hover, .cb-stepper ul li.cb-stepper-disabled a:focus,
.cb-stepper ol li.cb-stepper-disabled a:hover,
.cb-stepper ol li.cb-stepper-disabled a:focus {
  text-decoration: none;
  cursor: not-allowed;
}
.cb-stepper ul li:last-child::after,
.cb-stepper ol li:last-child::after {
  width: 0;
  height: 0;
}
.cb-stepper:not(.cb-stepper-simple) li.cb-stepper-complete .cb-glyph::before {
  content: "\e90b";
}
.cb-stepper:not(.cb-stepper-simple) li.cb-stepper-complete .cb-icon::before {
  content: "\e912";
}
@media (max-width: 1023.98px) {
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol {
    flex-direction: column;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul li,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol li {
    display: flex;
    align-items: center;
    flex-flow: wrap;
    text-align: left;
    padding: 0;
    margin-bottom: 44px;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul li a,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol li a {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul li p,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol li p {
    padding-left: 16px;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul li p + p,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol li p + p {
    flex: 100%;
    padding-left: 48px;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul li::after,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol li::after {
    height: 100%;
    width: 2px;
    top: 38px;
    left: 15px;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ul li:last-child::after,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix) ol li:last-child::after {
    width: 0;
    height: 0;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix).cb-stepper-content-glyph ul li,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix).cb-stepper-content-glyph ol li {
    margin-bottom: 64px;
  }
  .cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix).cb-stepper-content-glyph ul li::after,
.cb-stepper:not(.cb-stepper-simple):not(.cb-stepper-horizontal-fix).cb-stepper-content-glyph ol li::after {
    height: calc(100% - 16px);
    width: 3px;
    top: 72px;
    left: 31px;
  }
}
.cb-stepper.cb-stepper-vertical ul,
.cb-stepper.cb-stepper-vertical ol {
  flex-direction: column;
}
.cb-stepper.cb-stepper-vertical ul li,
.cb-stepper.cb-stepper-vertical ol li {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  text-align: left;
  padding: 0;
  margin-bottom: 44px;
}
.cb-stepper.cb-stepper-vertical ul li a,
.cb-stepper.cb-stepper-vertical ol li a {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}
.cb-stepper.cb-stepper-vertical ul li p,
.cb-stepper.cb-stepper-vertical ol li p {
  padding-left: 16px;
}
.cb-stepper.cb-stepper-vertical ul li p + p,
.cb-stepper.cb-stepper-vertical ol li p + p {
  flex: 100%;
  padding-left: 48px;
}
.cb-stepper.cb-stepper-vertical ul li::after,
.cb-stepper.cb-stepper-vertical ol li::after {
  height: 100%;
  width: 2px;
  top: 38px;
  left: 15px;
}
.cb-stepper.cb-stepper-vertical ul li:last-child::after,
.cb-stepper.cb-stepper-vertical ol li:last-child::after {
  width: 0;
  height: 0;
}
.cb-stepper.cb-stepper-vertical.cb-stepper-content-glyph ul li,
.cb-stepper.cb-stepper-vertical.cb-stepper-content-glyph ol li {
  margin-bottom: 64px;
}
.cb-stepper.cb-stepper-vertical.cb-stepper-content-glyph ul li::after,
.cb-stepper.cb-stepper-vertical.cb-stepper-content-glyph ol li::after {
  height: calc(100% - 16px);
  width: 3px;
  top: 72px;
  left: 31px;
}
.cb-stepper.cb-stepper-simple ul li p,
.cb-stepper.cb-stepper-simple ol li p {
  display: none;
  visibility: hidden;
}
.cb-stepper.cb-stepper-simple ul li .cb-glyph,
.cb-stepper.cb-stepper-simple ul li .cb-icon,
.cb-stepper.cb-stepper-simple ol li .cb-glyph,
.cb-stepper.cb-stepper-simple ol li .cb-icon {
  color: #324dc7;
}
.cb-stepper.cb-stepper-simple ul li .cb-glyph.cb-circle,
.cb-stepper.cb-stepper-simple ul li .cb-icon.cb-circle,
.cb-stepper.cb-stepper-simple ol li .cb-glyph.cb-circle,
.cb-stepper.cb-stepper-simple ol li .cb-icon.cb-circle {
  color: #888888;
}
.cb-stepper.cb-stepper-simple ul li.cb-stepper-skip .cb-glyph.cb-circle, .cb-stepper.cb-stepper-simple ul li.cb-stepper-skip .cb-glyph.cb-disc,
.cb-stepper.cb-stepper-simple ul li.cb-stepper-skip .cb-icon.cb-circle,
.cb-stepper.cb-stepper-simple ul li.cb-stepper-skip .cb-icon.cb-disc, .cb-stepper.cb-stepper-simple ul li.cb-stepper-active .cb-glyph.cb-circle, .cb-stepper.cb-stepper-simple ul li.cb-stepper-active .cb-glyph.cb-disc,
.cb-stepper.cb-stepper-simple ul li.cb-stepper-active .cb-icon.cb-circle,
.cb-stepper.cb-stepper-simple ul li.cb-stepper-active .cb-icon.cb-disc,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-skip .cb-glyph.cb-circle,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-skip .cb-glyph.cb-disc,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-skip .cb-icon.cb-circle,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-skip .cb-icon.cb-disc,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-active .cb-glyph.cb-circle,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-active .cb-glyph.cb-disc,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-active .cb-icon.cb-circle,
.cb-stepper.cb-stepper-simple ol li.cb-stepper-active .cb-icon.cb-disc {
  color: #324dc7;
}
.cb-stepper.cb-stepper-simple ul li::after,
.cb-stepper.cb-stepper-simple ol li::after {
  width: calc(100% - 24px);
  top: 9px;
  left: calc(50% + 12px);
}
.cb-stepper.cb-stepper-content-glyph ul li::after,
.cb-stepper.cb-stepper-content-glyph ol li::after {
  height: 2px;
  width: calc(100% - 80px);
  background: #888888;
  content: "";
  display: block;
  position: absolute;
  top: 32px;
  left: calc(50% + 40px);
}
.cb-stepper.cb-stepper-content-glyph ul li:last-child::after,
.cb-stepper.cb-stepper-content-glyph ol li:last-child::after {
  width: 0;
  height: 0;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li .cb-glyph-content-multi {
  background-color: #324dc7;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li.cb-stepper-skip::after {
  background: #324dc7;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue5 ol li.cb-stepper-complete::after {
  background: #324dc7;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li .cb-glyph-content-multi {
  background-color: #009cde;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li.cb-stepper-skip::after {
  background: #009cde;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue3 ol li.cb-stepper-complete::after {
  background: #009cde;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li .cb-glyph-content-multi {
  background-color: #71c5e8;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li.cb-stepper-skip::after {
  background: #71c5e8;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-blue4 ol li.cb-stepper-complete::after {
  background: #71c5e8;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li .cb-glyph-content-multi {
  background-color: #fedb00;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li .cb-glyph-content-multi::before {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li.cb-stepper-skip::after {
  background: #fedb00;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-yellow1 ol li.cb-stepper-complete::after {
  background: #fedb00;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li .cb-glyph-content-multi {
  background-color: #702f8a;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li.cb-stepper-skip::after {
  background: #702f8a;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple1 ol li.cb-stepper-complete::after {
  background: #702f8a;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li .cb-glyph-content-multi {
  background-color: #a05eb5;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li.cb-stepper-skip::after {
  background: #a05eb5;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-purple2 ol li.cb-stepper-complete::after {
  background: #a05eb5;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li .cb-glyph-content-multi {
  background-color: #3a913f;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li.cb-stepper-skip::after {
  background: #3a913f;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green1 ol li.cb-stepper-complete::after {
  background: #3a913f;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li .cb-glyph-content-multi {
  background-color: #6cc24a;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li .cb-glyph-content-multi::after {
  color: #1e1e1e;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li.cb-stepper-skip .cb-glyph-content-multi,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li.cb-stepper-skip .cb-glyph-content-multi {
  background-color: #888888;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li.cb-stepper-skip .cb-glyph-content-multi::before,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li.cb-stepper-skip .cb-glyph-content-multi::before {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li.cb-stepper-skip .cb-glyph-content-multi::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li.cb-stepper-skip .cb-glyph-content-multi::after {
  color: #ffffff;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li.cb-stepper-skip::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li.cb-stepper-skip::after {
  background: #6cc24a;
}
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ul li.cb-stepper-complete::after,
.cb-stepper.cb-stepper-content-glyph.cb-glyph-green2 ol li.cb-stepper-complete::after {
  background: #6cc24a;
}